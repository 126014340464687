import StripeConnect from "components/stripeConnect";
import { Column } from "helpers/generalStyles";
import React from "react";

const StripeConnectPage = ({ t }: { t: any }) => {
  return (
    <div style={{ width: "100%", borderTop: "1px solid #b2b2b2" }}>
      <Column width="100%" center alignCenter style={{ padding: "30px 0" }}>
        <h2>{t("paymentMethodConnexion")}</h2>
        <StripeConnect />{" "}
      </Column>
    </div>
  );
};

export default StripeConnectPage;
