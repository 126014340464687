import { Options } from "./inputs.types";

export const getSelectedLabelsForPlaceholderDisplay = (
  selected: string[],
  options: Options,
): string => {
  return options
    .filter((option) => selected.includes(option.value))
    .map((option) => option.label)
    .join(", ");
};
