import styled from "styled-components";
import {
  Column,
  MainTitleText,
  Row,
  SecondaryText,
  Text,
} from "helpers/generalStyles";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";

export interface Navigations {
  title: string;
  icon?: React.ReactNode;
  path: string;
}

export default function MainTitle(props: {
  title: string;
  navigations?: Navigations[];
}) {
  const navigate = useNavigate();
  const { title, navigations } = props;
  const user = useSelector((state: RootState) => state.user);

  const isNavigationButtonSelected = (path: string) =>
    window.location.pathname === path;

    return (
    <MainTitleDiv>
      <MainTitleText>{title.toUpperCase()}</MainTitleText>
      <Column>
        <Text size="18px">{user?.user?.fullName || "-"}</Text>
        <SecondaryText>{user?.user?.email || "-"}</SecondaryText>
      </Column>

      {navigations && (
        <Row>
          {navigations.map((navigation, index) => (
            <Button
              sx={{ mr: 2 }}
              variant={
                isNavigationButtonSelected(navigation.path)
                  ? "contained"
                  : "outlined"
              }
              key={index}
              onClick={() => navigate(navigation.path)}
            >
              {navigation.icon}
              {navigation.title}
            </Button>
          ))}
        </Row>
      )}
    </MainTitleDiv>
  );
}

const MainTitleDiv = styled.div`
  box-sizing: border-box;
  padding: 0 1rem 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #e2e2e2;
`;
