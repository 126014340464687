import { TextField } from "@mui/material";

interface AddMinimumAverageCartProps {
  t: any;
  disabled?: boolean;
  value: number;
  onChange: (value: number) => void
}

const AddMinimumAverageCart = ({
  t,
  disabled,
  value,
  onChange
}: AddMinimumAverageCartProps) => {
  return (
    <TextField
      inputProps={{
        type: "number",
        inputMode: "decimal",
        min: 1,
        step: 1,
      }}
      disabled={disabled}
      label={t("form.create.minimumCartToAcceptDiscount")}
      id="minBasket"
      multiline={false}
      variant="outlined"
      required
      fullWidth={true}
      type={"decimal"}
      value={String(value)}
      onChange={(event) => {
        const value = event.target.value;
        if (value === "") onChange(0);
        else {
          onChange(Number(value));
        }
      }}
    />
  );
};

export default AddMinimumAverageCart;
