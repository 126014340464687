import React from "react";
import { SelectChangeEvent } from "@mui/material";
import { InputCheckboxSelect } from "components/formInputs/Inputs";
import { Activity } from "backend/api/activities";

interface SelectActivitiesProps {
  selectedActivityIds: string[];
  activities: Activity[];
  handleSelectChange: (event: SelectChangeEvent<(string | number)[]>) => void;
  errors?: any;
  t: any;
  id?: string;
  label?: string;
  resetSelection?: boolean;
}

const SelectActivities = ({
  selectedActivityIds,
  activities,
  handleSelectChange,
  errors,
  t,
  id,
  label,
  resetSelection,
}: SelectActivitiesProps) => {
  const options = [
    ...(activities?.map((activity) => ({
      value: activity.id.toString(),
      label: activity.informations.name,
    })) || []),
  ];
  return (
    <InputCheckboxSelect
      multiple
      values={selectedActivityIds.map((id) => id.toString())}
      onChange={handleSelectChange}
      placeholder={label ?? t("form.create.associateActivities")}
      id={id || "activity_id"}
      name={"activity_id"}
      options={options}
      required
      error={errors && errors.activity_id}
      resetSelection={resetSelection}
    />
  );
};

export default SelectActivities;
