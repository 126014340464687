import React from "react";
import { useTranslation } from "react-i18next";
import { DiscountCardContainer, DeleteIcon, DiscountNameContainer, EndTextStatus } from "./style";
import { Text } from "helpers/generalStyles";
import SelectActivities from "./SelectActivities";
import { DiscountWithActivities } from "./DiscountList";
import RemoveDiscountModal from "./RemoveDiscountModal";
import AddUsageLimit from "./AddUsageLimit";
import AddMinimumAverageCart from "./AddMinimumAverageCart";
import AddDiscountDate from "./AddDiscountDate";
import AddLastDayDiscount from "./AddLastDayDiscount";
import { MAX_USE_AMOUNT, MAX_USE_TICKETS, TERMINATED } from "constants/discountConstants";
import { noop } from "lodash";

interface Props {
  discount: DiscountWithActivities;
}

const DiscountCard = ({ discount }: Props) => {
  const { t } = useTranslation("discounts");
  const [isRemoveModalDisplayed, setIsRemoveModalDisplayed] =
    React.useState<boolean>(false);

  return (
    <DiscountCardContainer>
      {isRemoveModalDisplayed && (
        <RemoveDiscountModal
          discount={discount}
          handleClose={() => setIsRemoveModalDisplayed(false)}
          t={t}
        />
      )}
      <DeleteIcon onClick={() => setIsRemoveModalDisplayed(true)} />
      <DiscountNameContainer>
        <Text size="22px" weight={700}>
          {discount.name}
        </Text>
        {discount.status === TERMINATED && (
          <EndTextStatus>
            {t('end')}
          </EndTextStatus>
        )}
      </DiscountNameContainer>
      <Text margin="8px 0 20px 0" size="18px">
        -{discount.discountNumber}% | code : {discount.code}
      </Text>
      <SelectActivities
        selectedActivityIds={discount.activities.map((activity) =>
          activity.id.toString(),
        )}
        activities={discount.activities}
        handleSelectChange={noop}
        t={t}
        id={"activity_id" + discount.id}
      />
      {(discount.maxUseAmount || discount.maxUseTickets) && (
        <AddUsageLimit
          t={t}
          valueAmount={discount.maxUseAmount || discount.maxUseTickets}
          valueType={discount.maxUseAmount ? MAX_USE_AMOUNT : MAX_USE_TICKETS}
          disabled
          onChangeAmount={noop}
          onChangeType={noop}
          options={[{ value: MAX_USE_TICKETS, label: t('form.create.maxUseTickets') }, { value: MAX_USE_AMOUNT, label: t('form.create.cumulativeAmount') }]}
        />
      )}
      {discount.minBasket && (
        <AddMinimumAverageCart
          t={t}
          value={discount.minBasket}
          onChange={noop}
          disabled
        />
      )}
      {(discount.startDate && discount.endDate) && (
        <AddDiscountDate
          t={t}
          valueStartDate={discount.startDate}
          valueEndDate={discount.endDate}
          onChangeStartDate={noop}
          onChangeEndDate={noop}
          disabled
        />
      )}
      {discount.lastDayDiscount && (
        <AddLastDayDiscount
          t={t}
          value={discount.lastDayDiscount}
          onChange={noop}
          disabled
        />
      )}
    </DiscountCardContainer>
  );
};

export default DiscountCard;
