import { DayListType } from "./HoursForm.types";

export const dayList: DayListType = ["1", "2", "3", "4", "5", "6", "0"];

export const daysNameMapping: any = {
  "1": "Lundi",
  "2": "Mardi",
  "3": "Mercredi",
  "4": "Jeudi",
  "5": "Vendredi",
  "6": "Samedi",
  "0": "Dimanche",
};

export const initialHoursObject = {
  "1": { slots: [] },
  "2": { slots: [] },
  "3": { slots: [] },
  "4": { slots: [] },
  "5": { slots: [] },
  "6": { slots: [] },
  "0": { slots: [] },
};

export const duplicateOpeningHoursBtnStyle = {
  color: "#fff",
  backgroundColor: "#008670",
  textTransform: "uppercase",
  width: "fit-content",
  borderRadius: "5px",
  textAlign: "center",
  border: "none",
  outline: "none",
  padding: "5px 10px",
  margin: "5px auto",
  cursor: "pointer",
  fontSize: "1rem",
};

export const minimalTimeslotValue = 5;
