import { useEffect, useState } from "react";
import { useUser } from "contexts/UserContext";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import * as zod from "zod";
import styled from "styled-components";
import { Box, Button } from "@mui/material";
import { InputPhone, InputText } from "components/formInputs/Inputs";
import { getClient, updateClient } from "backend/api/clients";
import AlertMessage from "components/general/AlertMessage";
import { FormDiv, MainPageCard } from "helpers/generalStyles";
import { Message } from "helpers/helpers";
import { useQuery } from "react-query";

type Props = {
  t: any;
  id: string;
};

export const ProfileSettings = ({ t, id }: Props) => {
  const { client } = useUser();
  const [isUserUpdated, setIsUserUpdated] = useState(false);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  const userSchema = zod.object({
    email: zod.string(),
    contactPhoneNumber: zod.string().min(10),
  });
  const [idClient, setIdClient] = useState<number>(0);

  type UserFormData = zod.infer<typeof userSchema>;

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    formState: { errors },
  } = useForm<UserFormData>({
    resolver: zodResolver(userSchema),
    defaultValues: {
      email: "",
      contactPhoneNumber: "",
    },
  });

  const phoneNumber = watch("contactPhoneNumber");

  useEffect(() => {
    setIsUserUpdated(phoneNumber !== client?.contactPhoneNumber);
  }, [phoneNumber, client?.contactPhoneNumber]);

  useEffect(() => {
    if (client) {
      reset(client);
    }
  }, [client, reset]);

  const { refetch: refetchClientSetting } = useQuery({
    queryKey: ["client-setting"],
    queryFn: () => getClient(),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setValue("email", data?.email);
      setValue("contactPhoneNumber", data?.contactPhoneNumber);
      setIdClient(data?.id);
    },
  });

  const handleSave = async (formData: UserFormData) => {
    try {
      await updateClient(idClient, formData);
      refetchClientSetting();
      setAlertMessage({ message: t("success.update"), type: "success" });
      setIsUserUpdated(false); // Reset the update flag after saving
    } catch (error) {
      setAlertMessage({ message: t("error.update"), type: "error" });
    }
  };

  return (
    <MainPageCard id={id}>
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={t}
      />
      <FormDiv style={{ gap: 0 }} onSubmit={handleSubmit(handleSave)}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0",
          }}
        >
          <h2 style={{ margin: 0 }}>{t("titleSetting").toUpperCase()}</h2>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            padding: "0",
            alignItems: "center",
          }}
        >
          <InputText
            label={t("inputLabels.email")}
            name="email"
            id="email"
            control={control}
            error={errors.email}
            disabled
            sx={{ mb: 2, mt: 5 }}
            t={t}
          />

          <InputPhone
            label={t("inputLabels.phone")}
            name="contactPhoneNumber"
            id="contactPhoneNumber"
            control={control}
            error={errors.contactPhoneNumber?.message}
            t={t}
          />
        </Box>

        {isUserUpdated && (
          <ButtonStyled
            sx={{ margin: "0 0 10px 20px", height: "60px" }}
            type="submit"
            variant="contained"
            color="primary"
          >
            {t("save")}
          </ButtonStyled>
        )}
      </FormDiv>
    </MainPageCard>
  );
};

export default ProfileSettings;

const ButtonStyled = styled(Button)`
  margin-top: 20px;
`;
