import { zodResolver } from '@hookform/resolvers/zod';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Button, IconButton, InputAdornment, TextField } from '@mui/material';
import LanguageSelector from 'components/general/LanguageSelector';
import { useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { z } from 'zod';
import { newPassword } from '../backend/api/auth';
import HopleisureSide from '../components/auth/HopleisureSide';
import AlertMessage from '../components/general/AlertMessage';
import { AuthTitle } from '../helpers/generalStyles';
import { Message } from '../helpers/helpers';

export default function NewPasswordPage() {
  const { t } = useTranslation('auth');
  const navigate = useNavigate();

  const [alertMessage, setAlertMessage] = useState<Message>({ type: "success", message: "" })

  return (
    <Page>
      <Container>
        <HopleisureSide />
        <FormCard>
          <AlertMessage alertMessage={alertMessage.message} setAlertMessage={setAlertMessage} type={alertMessage.type} t={t} />
          <LanguageSelector />
          {PwdResetForm(setAlertMessage, navigate, t)}
        </FormCard>
      </Container>
    </Page>
  );
}

function PwdResetForm(setAlertMessage: any, navigate: any, t: any) {
  let [searchParams] = useSearchParams();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);
  const updatePasswordFormInput = z.object({
    password: z.string().min(8),
    passwordConfirmation: z.string().min(8)
  }).refine((body: any) => body.password === body.passwordConfirmation, {
    path: ["passwordConfirmation"],
    message: t('password_mismatch'),
  })

  type UpdatePasswordFormInput = z.infer<typeof updatePasswordFormInput>;

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(updatePasswordFormInput),
    defaultValues: {
      password: '',
      passwordConfirmation: '',
    }
  })

  const mutateSignup = useMutation({
    mutationFn: async (data: UpdatePasswordFormInput) => newPassword({ ...data, tokenHash: searchParams.get('token_hash') || '' }),
    onSuccess: (result) => {
      setAlertMessage({ type: 'success', message: t('USER_PASSWORD_CREATED') });
      setTimeout(() => {
        navigate("/login");
      }, 3500);
    },
    onError: (error: any) => {
      setAlertMessage({ type: 'error', message: t(`${error?.response?.data?.error}`) });
    },
  })
  const onSubmit: SubmitHandler<any> = (data: UpdatePasswordFormInput) => {
    mutateSignup.mutate({ password: data.password, passwordConfirmation: data.passwordConfirmation })
  }

  return (
    <FormDiv onSubmit={handleSubmit(onSubmit)}>
      <AuthTitle>{t("newPasswordTitle").toUpperCase()}</AuthTitle>
      <Controller
        name="password"
        control={control}
        render={({ field }) =>
          <TextField
            fullWidth={true}
            id="password"
            label={t("password")}
            type={showPassword ? "text" : "password"}
            variant="standard"
            error={Boolean(errors.password)}
            helperText={errors.password ? t(errors?.password.type) : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ width: '70%' }}
            {...field}
          />
        }
      />
      <Controller
        name="passwordConfirmation"
        control={control}
        render={({ field }) =>
          <TextField
            fullWidth={true}
            id="passwordConfirmation"
            label={t("newPasswordConfirmation")}
            type={showPasswordConfirmation ? "text" : "password"}
            variant="standard"
            error={Boolean(errors.passwordConfirmation)}
            helperText={errors.passwordConfirmation ? t(errors?.passwordConfirmation.type) === 'custom' ? errors?.passwordConfirmation.message : t(errors?.passwordConfirmation.type) : ''}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPasswordConfirmation(!showPasswordConfirmation)}
                    edge="end"
                  >
                    {showPasswordConfirmation ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            style={{ width: '70%' }}
            {...field}
          />
        }
      />

      <Button fullWidth={true} variant="contained" type="submit">{t('newPasswordButton')}</Button>
    </FormDiv>
  )
}

const Page = styled.div`
    display: flex;
    height: 100vh;
    position: relative;
    background-color: #054643;
  `;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex: 1;
    padding: 30px;
    gap: 30px;
  `;

const FormCard = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex:3;
  background-color: white;
  height: 100%;
  border-radius: 20px;
`;

const FormDiv = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70%;
  min-width: 400px;
  gap: 30px;
`;