import { z } from "zod";
import { numberablePositiveString } from "../../../helpers/helpers";
import { ActivityPricesTypeEnum } from "helpers/constants";

// begin form variables
const slot = z.object({
  to: z.string().min(5),
  from: z.string().min(5),
});

const priceForm = z.object({
  number: z.number().min(0),
  price: z.number().min(0),
  name: z.string().min(5),
  bookingId: z.number().optional(),
  type: z.nativeEnum(ActivityPricesTypeEnum).optional(),
});

export const bookingFormValidator = z.object({
  activityId: numberablePositiveString,
  date: z.any(),
  slots: z.array(slot).length(1),
  prices: z.array(priceForm),
  fullName: z.string().min(2),
  email: z.string().email(),
  phone: z.string().min(10),
});

export type BookingFormValidator = z.infer<typeof bookingFormValidator>;
export type PriceForm = z.infer<typeof priceForm>;
