import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Navbar from "../../components/navigation/Navbar";
import AlertMessage from "../../components/general/AlertMessage";
import { useEffect, useMemo, useState } from "react";
import { Message, checkOverlap } from "../../helpers/helpers";
import MainTitle from "../../components/general/MainTitle";
import {
  Calendar as BigCalendar,
  Formats,
  momentLocalizer,
} from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-big-calendar/lib/addons/dragAndDrop/styles.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../../helpers/css/myCalendar.css";
import moment from "moment";
import "moment/locale/fr";
import { useQuery } from "react-query";
import { getAllActivitiesAvailabilities } from "../../backend/api/activities";
import { EventData, NewBooking } from "../../components/agenda/types";
import { AgendaEventUpdateModal } from "../../components/agenda/mainModals/AgendaEventUpdateModal";
import { AgendaEventChangeEventStatusModal } from "../../components/agenda/mainModals/AgendaEventChangeEventStatusModal";
import AgendaEvent from "../../components/agenda/AgendaEvent";
import AgendaEventBookingAddUpdateModal from "../../components/agenda/mainModals/AgendaEventBookingAddUpdateModal";
import "./Agenda.style.css";
import { CalendarMomentOfDayEnum, CalendarViewEnum } from "./Agenda.types";
import { AgendaHeader } from "components/agenda/agendaHeader/AgendaHeader";
import AgendaFilters from "components/agenda/agendaFilters/AgendaFilters";
import { ActivityFilterEnum } from "components/agenda/agendaFilters/AgendaFilters.constants";

export default function Agenda() {
  const { t } = useTranslation("agenda");
  const navigate = useNavigate();
  const [events, setEvents] = useState<EventData[]>([]);
  const [filteredEvents, setFilteredEvents] = useState(events);
  const [selectedEventForUpdate, setSelectedEventForUpdate] =
    useState<EventData | null>(null);
  const [selectedEventForChangeStatus, setSelectedEventForChangeStatus] =
    useState<EventData | null>(null);
  const [selectedBooking, setSelectedBooking] = useState<NewBooking | null>(
    null,
  );
  const [selectedFilter, setSelectedFilter] = useState("");

  // for agenda Header
  const [view, setView] = useState<CalendarViewEnum>(CalendarViewEnum.WEEK);
  const [date, setDate] = useState(moment());
  const [selectedMoment, setSelectedMoment] = useState<
    CalendarMomentOfDayEnum[]
  >([]);
  const [selectedActivities, setSelectedActivities] = useState<string[]>([]);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  useEffect(() => {
    const filteredEventsTmp = events
      .filter(({ data: { numberOfUnitBooked, maxCapacity } }) => {
        switch (selectedFilter) {
          case ActivityFilterEnum.ActivityWithBookings:
            return numberOfUnitBooked > 0;
          case ActivityFilterEnum.ActivityFullyBooked:
            return numberOfUnitBooked === maxCapacity;
          case ActivityFilterEnum.ActivityWithAvailabilities:
            return numberOfUnitBooked < maxCapacity;
          default:
            return true;
        }
      })
      .filter((item: any) => {
        if (selectedActivities.length === 0)
          return filterEventBySelectedMoment(item);
        else
          return (
            item.data &&
            selectedActivities.includes(item.data?.title) &&
            filterEventBySelectedMoment(item)
          );
      });
    setFilteredEvents(filteredEventsTmp);
  }, [events, selectedActivities, selectedMoment, selectedFilter]);

  const filterEventBySelectedMoment = (event: EventData) => {
    if (selectedMoment.length === 0) return true;
    const selectedMomentTmp = selectedMoment[0];
    const momentStartDate = moment(event.start);
    const momentEndDate = moment(event.end);
    let startMomentOfDay = moment(event.start);
    let endMomentOfDay = moment(event.end);
    // the day chose is a random date, no matter what date we chose for react big calendar library, what is important is the hours in the date
    if (selectedMomentTmp === CalendarMomentOfDayEnum.MORNING) {
      startMomentOfDay.set("hours", 6);
      startMomentOfDay.set("minutes", 0);
      endMomentOfDay.set("hours", 13);
      endMomentOfDay.set("minutes", 0);
    }
    if (selectedMomentTmp === CalendarMomentOfDayEnum.AFTERNOON) {
      startMomentOfDay.set("hours", 12);
      startMomentOfDay.set("minutes", 0);
      endMomentOfDay.set("hours", 19);
      endMomentOfDay.set("minutes", 0);
    }
    if (selectedMomentTmp === CalendarMomentOfDayEnum.EVENING) {
      startMomentOfDay.set("hours", 17);
      startMomentOfDay.set("minutes", 0);
      endMomentOfDay.set("hours", 23);
      endMomentOfDay.set("minutes", 59);
    }
    return checkOverlap(
      momentStartDate,
      momentEndDate,
      startMomentOfDay,
      endMomentOfDay,
    );
  };

  // agenda init
  const localizer = momentLocalizer(moment);
  let from = date.format("YYYY-MM-DD");
  let to = date.format("YYYY-MM-DD");

  if (view === CalendarViewEnum.WEEK) {
    from = moment(date).startOf("W").format("YYYY-MM-DD");
    to = moment(date).endOf("W").format("YYYY-MM-DD");
  } else if (view === CalendarViewEnum.DAY) {
    from = date.format("YYYY-MM-DD");
    to = date.format("YYYY-MM-DD");
  }

  useQuery({
    queryKey: ["getActivitiesAvailabilities", from, to],
    queryFn: () => getAllActivitiesAvailabilities(from, to),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      const fmtData = data.map((item) => {
        return {
          ...item,
          start: new Date(item.start),
          end: new Date(item.end),
        };
      });
      setEvents(fmtData);
    },
  });

  // Manage the moment of days displayed in calendar
  const getMinHours = () => {
    if (selectedMoment.length === 0) return undefined;
    const selectedMomentTmp = selectedMoment[0];
    // the day chose is a random date, no matter what date we chose for react big calendar library, what is important is the hours in the date
    if (selectedMomentTmp === CalendarMomentOfDayEnum.MORNING)
      return new Date("2024-10-20 06:00");
    if (selectedMomentTmp === CalendarMomentOfDayEnum.AFTERNOON)
      return new Date("2024-10-20 12:00");
    if (selectedMomentTmp === CalendarMomentOfDayEnum.EVENING)
      return new Date("2024-10-20 17:00");
  };

  const getMaxHours = () => {
    if (selectedMoment.length === 0) return undefined;
    const selectedMomentTmp = selectedMoment[0];
    // the day chose is a random date, no matter what date we chose for react big calendar library, what is important is the hours in the date
    if (selectedMomentTmp === CalendarMomentOfDayEnum.MORNING)
      return new Date("2024-10-20 13:00");
    if (selectedMomentTmp === CalendarMomentOfDayEnum.AFTERNOON)
      return new Date("2024-10-20 19:00");
    if (selectedMomentTmp === CalendarMomentOfDayEnum.EVENING)
      return new Date("2024-10-20 23:59");
  };

  const minCalendarHour = useMemo(() => {
    return getMinHours();
  }, [selectedMoment]);

  const maxCalendarHour = useMemo(() => {
    return getMaxHours();
  }, [selectedMoment]);

  // will be used to create columns in the calendar in day view
  const resourceMap = useMemo(() => {
    const uniqueEventActivityTitles = [
      // @ts-ignore
      ...new Set(events.map((item) => item.data.title)),
    ];
    return uniqueEventActivityTitles.map((item) => {
      return {
        resourceId: item,
        resourceTitle: item,
      };
    });
  }, [events]);

  // calendar event coloration
  const titleEventColorMapping = useMemo(() => {
    const listOfColors = ["#fff6d7", "#fcf3f3", "#f5fcf3", "#f3f9fc"];
    const colorMapping: any = {};
    resourceMap
      .map((item) => item.resourceId)
      .forEach((resource, index) => {
        colorMapping[resource] = listOfColors[index % listOfColors.length];
      });
    return colorMapping;
  }, [resourceMap]);

  const formats: Formats = useMemo(() => {
    return {
      dayFormat: (date, culture, localizer: any) =>
        localizer.format(date, "dddd DD", culture),
    };
  }, []);

  function CustomAgendaEvent(props: { event: any }) {
    return (
      <AgendaEvent
        event={props.event as EventData}
        titleEventColorMapping={titleEventColorMapping}
        setSelectedEventForUpdate={setSelectedEventForUpdate}
        setSelectedEventForChangeStatus={setSelectedEventForChangeStatus}
        setSelectedBooking={setSelectedBooking}
        navigate={navigate}
        t={t}
      />
    );
  }

  return (
    <Page>
      <Navbar />
      <Container>
        <AlertMessage
          alertMessage={alertMessage.message}
          setAlertMessage={setAlertMessage}
          type={alertMessage.type}
          t={t}
        />
        <MainTitle title={t("agendaTitle")} />
        <div style={{ width: "100%" }}>
          {/* We will uncomment later when we will activate the features */}
          {/* <CancelOrBlockSlotsModal open={openCancelOrBlockSlotsModal} setOpen={setOpenCancelOrBlockSlotsModal} t={t} /> */}
          {selectedEventForUpdate ? (
            <AgendaEventUpdateModal
              event={selectedEventForUpdate}
              setSelectedEvent={setSelectedEventForUpdate}
              setSelectedBooking={setSelectedBooking}
              t={t}
            />
          ) : null}
          {selectedEventForChangeStatus ? (
            <AgendaEventChangeEventStatusModal
              selectedEvent={selectedEventForChangeStatus}
              setSelectedEvent={setSelectedEventForChangeStatus}
              setAlertMessage={setAlertMessage}
              t={t}
            />
          ) : null}
          {selectedBooking ? (
            <AgendaEventBookingAddUpdateModal
              selectedBooking={selectedBooking}
              setSelectedBooking={setSelectedBooking}
              setAlertMessage={setAlertMessage}
              t={t}
            />
          ) : null}
          <AgendaFilters
            events={events}
            selectedActivities={selectedActivities}
            setSelectedActivities={setSelectedActivities}
            setSelectedFilter={setSelectedFilter}
            t={t}
          />
          <AgendaHeader
            events={events}
            date={date}
            view={view}
            setDate={setDate}
            setView={setView}
            selectedMoment={selectedMoment}
            setSelectedMoment={setSelectedMoment}
            selectedActivities={selectedActivities}
            setSelectedActivities={setSelectedActivities}
            t={t}
          />
          <BigCalendar
            toolbar={false}
            localizer={localizer}
            events={filteredEvents}
            startAccessor="start"
            endAccessor="end"
            style={{
              maxHeight: "730px",
              paddingLeft: "30px",
              paddingRight: "30px",
            }}
            dayLayoutAlgorithm={"no-overlap"}
            views={[CalendarViewEnum.WEEK, CalendarViewEnum.DAY]}
            resourceIdAccessor={
              view === CalendarViewEnum.DAY ? "resourceId" : undefined
            }
            resources={view === CalendarViewEnum.DAY ? resourceMap : undefined}
            resourceTitleAccessor={
              view === CalendarViewEnum.DAY ? "resourceTitle" : undefined
            }
            step={30}
            view={view}
            date={date.toDate()}
            min={minCalendarHour}
            max={maxCalendarHour}
            components={{
              event: CustomAgendaEvent,
            }}
            onView={(newView: any) => setView(newView)} // Handle view changes
            onNavigate={(newDate: any) => setDate(moment(newDate))} // Handle date navigation
            formats={formats}
          />
        </div>
      </Container>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  position: relative;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
`;
