import React, { useState } from "react";
import { useForm, SubmitHandler, FieldValues } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { InputText, UncontrolledCheckBox } from "components/formInputs/Inputs";
import { useUser } from "contexts/UserContext";
import { Activity } from "backend/api/activities";
import { FormDiv, Column, Text } from "helpers/generalStyles";
import { createDiscount, Discount } from "backend/api/discounts";
import { Container, GenerateIcon, DiscountDiv } from "./style";
import { Button, CircularProgress, SelectChangeEvent } from "@mui/material";
import AlertMessage from "components/general/AlertMessage";
import { Message } from "helpers/helpers";
import SelectActivities from "./SelectActivities";
import { DiscountTypesEnum } from "helpers/constants";
import { queryClient } from "index";
import AddUsageLimit from "./AddUsageLimit";
import AddMinimumAverageCart from "./AddMinimumAverageCart";
import AddDiscountDate from "./AddDiscountDate";
import AddLastDayDiscount from "./AddLastDayDiscount";
import { MAX_USE_AMOUNT, MAX_USE_TICKETS } from "constants/discountConstants";

interface CreatedDiscount {
  name: string;
  discountType: DiscountTypesEnum;
  discountNumber: number;
  code: string;
  activity_id?: number | string;
}

interface Props {
  t: any;
  activities: Activity[];
  discounts: Discount[];
}

interface PromotionBase {
  name: string;
  discountNumber: number;
  code: string;
  discountType: DiscountTypesEnum;
  clientId: number;
  activityId: number;
  maxUseTickets?: number;
  maxUseAmount?: number;
  minBasket?: number;
  startDate?: string;
  endDate?: string;
  lastDayDiscount?: number;
}

const CreateDiscount = ({ t, activities, discounts }: Props) => {
  const { client } = useUser();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isUsageLimit, setIsUsageLimit] = useState<boolean>(false);
  const [isMinimumAverage, setIsMinimumAverage] = useState<boolean>(false);
  const [isDiscountDate, setIsDiscountDate] = useState<boolean>(false);
  const [isLastDayDiscount, setIsLastDayDiscount] = useState<boolean>(false);
  const [limitAmount, setLimitAmount] = useState<number>(0);
  const [limitType, setLimitType] = useState<string>("");
  const [minBasket, setMinBasket] = useState<number>(0);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");
  const [lastDayDiscount, setLastDayDiscount] = useState<number>(0);
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });
  const [selectedActivityIds, setSelectedActivityIds] = React.useState<
    Array<string | number>
  >([]);

  const promotionSchema = z.object({
    name: z.string(),
    discountNumber: z.number().int().min(1).max(100),
    code: z.string(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: zodResolver(promotionSchema),
  });

  const isDiscountWithCodeAlreadyExists = (code: string) => {
    return discounts.some((discount) => discount.code === code);
  };

  const validActivities: Activity[] = activities.filter((activity) => !activity.informations.isDeleted);

  const onSubmit = async (promotion: CreatedDiscount) => {
    try {
      if (client) {
        if (isDiscountWithCodeAlreadyExists(promotion.code)) {
          setAlertMessage({
            message: t("errors.codeAlreadyExists"),
            type: "error",
          });
          return;
        }
        setIsLoading(true);
        const promotions = selectedActivityIds.map((activityId) => {
          const basePromotion: PromotionBase = {
            ...promotion,
            discountType: DiscountTypesEnum.PERCENTAGE,
            clientId: Number(client.id),
            activityId: Number(activityId),
          };

          if (isUsageLimit) {
            if (limitType === MAX_USE_TICKETS) {
              basePromotion.maxUseTickets = limitAmount;
            } else if (limitType === MAX_USE_AMOUNT) {
              basePromotion.maxUseAmount = limitAmount;
            }
          }

          if (isMinimumAverage) {
            basePromotion.minBasket = minBasket;
          }

          if (isDiscountDate) {
            basePromotion.startDate = startDate;
            basePromotion.endDate = endDate;
          }

          if (isLastDayDiscount) {
            basePromotion.lastDayDiscount = lastDayDiscount;
          }

          return basePromotion;
        });

        await Promise.all(
          promotions.map((promotion) => {
            return createDiscount(promotion);
          }),
        );

        queryClient.invalidateQueries({ queryKey: ["getDiscounts"] });
        setIsLoading(false);
        setAlertMessage({ message: t("success.create"), type: "success" });
        reset();
        setSelectedActivityIds([]);
        setLimitAmount(0);
        setLimitType("");
        setStartDate("");
        setEndDate("");
        setLastDayDiscount(0);
        setMinBasket(0);
        setIsUsageLimit(false);
        setIsMinimumAverage(false);
        setIsDiscountDate(false);
        setIsLastDayDiscount(false);
      }
    } catch (error: any) {
      setIsLoading(false);
      setAlertMessage({
        message: t("errors." + error.response.data.error),
        type: "error",
      });
    }
  };

  const generateCode = () => {
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < 12; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length),
      );
    }
    return result;
  };

  // Fonction à appeler lorsque le suffixe est cliqué
  const handleCodeGeneratorClick = () => {
    const newCode = generateCode();
    // Mettre à jour la valeur du champ 'code'
    setValue("code", newCode, { shouldValidate: true });
  };

  const handleSelectChange = (
    event: SelectChangeEvent<typeof selectedActivityIds>,
  ) => {
    const {
      target: { value },
    } = event;
    setSelectedActivityIds(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  return (
    <Container style={{ width: "400px" }}>
      <AlertMessage
        alertMessage={alertMessage.message}
        type={alertMessage.type}
        t={t}
      />
      {isLoading ? (
        <Column center alignCenter>
          <CircularProgress color="success" />
        </Column>
      ) : (
        <FormDiv
          style={{ width: "100%", minWidth: "auto", padding: 0 }}
          onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
        >
          <Text size="24px" weight={700} color="black">
            {t("form.create.title")}
          </Text>
          <InputText
            label={t("form.create.name")}
            name="name"
            id="coupon-name"
            control={control}
            error={errors.name?.message}
            t={t}
          />
          <InputText
            label={t("form.create.discountPercentage")}
            name="discountNumber"
            id="discountNumber"
            control={control}
            max={100}
            min={1}
            inputType="number"
            error={errors.discountNumber?.message}
            t={t}
          />
          <InputText
            label={t("form.create.code")}
            name="code"
            id="activation-code"
            control={control}
            error={errors.code?.message}
            t={t}
            suffix={<GenerateIcon onClick={handleCodeGeneratorClick} />}
          />
          <SelectActivities
            selectedActivityIds={selectedActivityIds.map((id) => id.toString())}
            activities={validActivities}
            handleSelectChange={handleSelectChange}
            errors={errors}
            t={t}
          />

          <DiscountDiv>
            <DiscountDiv>
              <UncontrolledCheckBox
                label={t("form.create.addUsageLimit")}
                isActive={isUsageLimit}
                uppercase={false}
                t={t}
                onChange={() => setIsUsageLimit((prev) => !prev)}
              />

              {isUsageLimit && (
                <AddUsageLimit
                  t={t}
                  valueAmount={limitAmount}
                  valueType={limitType}
                  onChangeAmount={(value: number) => {
                    setLimitAmount(value);
                  }}
                  onChangeType={(event: any) => {
                    setLimitType(event.target.value);
                  }}
                  options={[
                    {
                      value: MAX_USE_TICKETS,
                      label: t("form.create.maxUseTickets"),
                    },
                    {
                      value: MAX_USE_AMOUNT,
                      label: t("form.create.cumulativeAmount"),
                    },
                  ]}
                />
              )}
            </DiscountDiv>

            <DiscountDiv>
              <UncontrolledCheckBox
                label={t("form.create.addMinimumAverageCart")}
                isActive={isMinimumAverage}
                uppercase={false}
                t={t}
                onChange={() => setIsMinimumAverage((prev) => !prev)}
              />
              {isMinimumAverage && (
                <AddMinimumAverageCart
                  t={t}
                  value={minBasket}
                  onChange={(value: number) => {
                    setMinBasket(value);
                  }}
                />
              )}
            </DiscountDiv>

            <DiscountDiv>
              <UncontrolledCheckBox
                label={t("form.create.addDiscountAvailabilityDates")}
                isActive={isDiscountDate}
                uppercase={false}
                t={t}
                onChange={() => setIsDiscountDate((prev) => !prev)}
              />
              {isDiscountDate && (
                <AddDiscountDate
                  t={t}
                  valueStartDate={startDate}
                  valueEndDate={endDate}
                  onChangeStartDate={(value: string) => {
                    setStartDate(value);
                  }}
                  onChangeEndDate={(value: string) => {
                    setEndDate(value);
                  }}
                />
              )}
            </DiscountDiv>

            <DiscountDiv>
              <UncontrolledCheckBox
                label={t("form.create.addLastMinutePromotion")}
                isActive={isLastDayDiscount}
                uppercase={false}
                t={t}
                onChange={() => setIsLastDayDiscount((prev) => !prev)}
              />
              {isLastDayDiscount && (
                <AddLastDayDiscount
                  t={t}
                  value={lastDayDiscount}
                  onChange={(value: number) => setLastDayDiscount(value)}
                />
              )}
            </DiscountDiv>
          </DiscountDiv>

          {errors.activity_id && <p>{t(errors.activity_id.message)}</p>}
          <Button
            sx={{ margin: "20px 0", width: "100%", fontSize: "22px" }}
            type="submit"
            variant="contained"
            color="info"
          >
            {t("form.create.button")}
          </Button>
        </FormDiv>
      )}
    </Container>
  );
};

export default CreateDiscount;
