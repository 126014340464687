import { useEffect, useMemo, useState } from "react";
import {
  Activity,
  ActivityAvailabilities,
  Availability,
  getActivities,
  getAvailabilities,
} from "../../../backend/api/activities";
import { Booking, NewBooking } from "../types";
import {
  BookingFormValidator,
  bookingFormValidator,
} from "../../bookings/bookingFormGroups/bookingFormValidator";
import moment from "moment";
import { useFieldArray, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useMutation, useQuery } from "react-query";
import { MainTitleText, ModalStyle } from "../../../helpers/generalStyles";
import { Button, Modal } from "@mui/material";
import BookingFormGroup1 from "../../bookings/bookingFormGroups/BookingFormGroup1";
import BookingFormGroup2 from "../../bookings/bookingFormGroups/BookingFormGroup2";
import BookingFormGroup3 from "../../bookings/bookingFormGroups/BookingFormGroup3";
import AgendaConfirmationModal from "../helpers/AgendaConfirmationModal";
import {
  PostBookingInput,
  PutBookingInput,
  postBooking,
  putBooking,
} from "../../../backend/api/bookings";
import { queryClient } from "../../..";
import {
  ActivityPricesStatusEnum,
  BookingStatusEnum,
  PlatformTypeEnum,
} from "../../../helpers/constants";
import { isBookingCanBeUpdated } from "../helpers/utis";
import { toastEmitter } from "helpers/helpers";
import { getTicketsNumber } from "components/bookings/bookingFormGroups/bookingUtils";

export default function AgendaEventBookingAddUpdateModal(props: {
  selectedBooking: Booking | NewBooking;
  setSelectedBooking: any;
  updateBookingStatus?: any;
  setAlertMessage: any;
  t: any;
}) {
  const {
    selectedBooking,
    setSelectedBooking,
    updateBookingStatus,
    setAlertMessage,
    t,
  } = props;
  const [activities, setActivities] = useState<Activity[]>([]);
  const [monthAvailabilities, setMonthAvailabilities] =
    useState<ActivityAvailabilities>([]);
  const [selectedDay, setSelectedDay] = useState<moment.Moment>(
    moment(selectedBooking.start),
  );
  const [selectedMonth, setSelectedMonth] = useState<number>(
    moment(selectedBooking.start).month(),
  );
  const [selectedYear, setSelectedYear] = useState<number>(
    moment(selectedBooking.start).year(),
  );

  const [availability, setAvaibility] = useState<Availability>();
  const [openUpdateConfirmationModal, setOpenUpdateConfirmationModal] =
    useState(false);

  // we can detect it's a new booking if there is not tickets yet in selectedBooking, cause all bookings should have unless one ticket
  const booking = selectedBooking as Booking;
  const isANewBooking = !booking.tickets;
  const bookingIds: number[] = booking.tickets
    ? booking.tickets.map((ticket) => ticket.bookingId)
    : [];
  const numberOfTicketsInTheBooking = booking.tickets
    ? booking.tickets.reduce((acc, curr) => acc + Number(curr.numberOfUnit), 0)
    : 0;

  const closeModal = () => setSelectedBooking(null);

  const initialFormValues: BookingFormValidator = {
    activityId: String(selectedBooking.activityId),
    date: selectedDay,
    slots: [],
    prices: [],
    fullName: booking.fullName ? booking.fullName : "",
    email: booking.email ? String(booking.email) : "",
    phone: booking.phoneNumber ? String(booking.phoneNumber) : "",
  };

  const form = useForm({
    resolver: zodResolver(bookingFormValidator),
    defaultValues: initialFormValues,
  });

  const values = form.getValues();
  const watch = form.watch();

  const formSlots = useFieldArray({
    control: form.control,
    name: "slots",
  });

  const formPrices = useFieldArray({
    control: form.control,
    name: "prices",
  });

  const activityId = values.activityId;
  const activity = useMemo(
    () => activities.find((item) => item.id === Number(activityId)),
    [activities, activityId],
  );

  // reset the number in the ticket prices when changing availability
  useEffect(() => {
    const bookingStartDate = booking?.start.split(" ")[1];
    const bookingEndDate = booking?.end.split(" ")[1];
    if (
      availability?.startTime !== bookingStartDate ||
      availability?.endTime !== bookingEndDate
    ) {
      const pricesWithNbOfUnitsReseted = formPrices.fields.map((item) => {
        return { ...item, number: 0 };
      });
      formPrices.replace(pricesWithNbOfUnitsReseted);
    }
  }, [availability]);

  useEffect(() => {
    const booking = selectedBooking as Booking;
    // init slots value
    if (booking.start && booking.end) {
      const bookingStartDate = booking?.start.split(" ")[1];
      const bookingEndDate = booking?.end.split(" ")[1];
      const selectedBookingSlots = [
        { from: bookingStartDate, to: bookingEndDate },
      ];
      formSlots.replace(selectedBookingSlots);
      const selectedAvailabilityDay = monthAvailabilities.find(
        (item) => item.date === selectedDay.format("YYYY-MM-DD"),
      );
      const matchedAvaibility = selectedAvailabilityDay?.availabilities.find(
        (item) =>
          item.startTime === bookingStartDate &&
          item.endTime === bookingEndDate,
      );
      setAvaibility(matchedAvaibility);
    }

    // init prices values
    if (activity?.prices) {
      const enabledPrices = activity?.prices
        .map((price) => {
          const ticket = booking.tickets?.find(
            (ticket) => ticket.name === price.name,
          );
          const numberOfUnitOnTicket = ticket ? ticket.numberOfUnit : 0;
          return {
            number: Number(numberOfUnitOnTicket),
            name: price.name,
            price: Number(price.price),
            bookingId: ticket?.bookingId,
            status: price.status,
            type: price.type || undefined,
          };
        })
        .filter((price) => price.status === ActivityPricesStatusEnum.ENABLED);
      formPrices.replace(enabledPrices);
    }
  }, [selectedBooking, activity, monthAvailabilities]);

  useEffect(() => {
    const activityId = watch.activityId;
    if (activityId) {
      updateMonthAvailabilities(selectedMonth, selectedYear, activityId);
    } else setMonthAvailabilities([]);
  }, [watch.activityId, selectedMonth]);

  useQuery({
    queryKey: ["getActivitiesWithDisabled"],
    queryFn: () => getActivities(true),
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setActivities(data);
    },
  });

  // #TODO: convert into useQuery call
  const updateMonthAvailabilities = async (
    month: number,
    year: number,
    activityId: string,
  ) => {
    try {
      const date = moment({ month, year });
      const data: any = await getAvailabilities(
        Number(activityId),
        date.startOf("month").format("YYYY-MM-DD"),
        date.endOf("month").format("YYYY-MM-DD"),
      );
      if (data?.message) setMonthAvailabilities([]);
      else {
        setMonthAvailabilities(data);
      }
    } catch (error: any) {
      console.log(error);
    }
  };

  const mutatePutBooking = useMutation({
    mutationFn: (data: PutBookingInput["body"]) =>
      putBooking(Number(activityId), data),
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesAvailabilities"],
      });
      queryClient.invalidateQueries({ queryKey: ["getBookings"] });
      setSelectedBooking(null);
      setAlertMessage({
        type: "success",
        message: t("success.updateBookingMessage"),
      });
    },
    onError: (error: any) => {
      console.log(error);
      closeModal();
      setAlertMessage({
        type: "error",
        message: t(`errors.${error?.response?.data?.error}`),
      });
    },
  });

  const mutatePostBooking = useMutation({
    mutationFn: (data: PostBookingInput["body"]) =>
      postBooking(Number(activityId), data),
    onSuccess: (result) => {
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesAvailabilities"],
      });
      queryClient.invalidateQueries({ queryKey: ["getBookings"] });
      setSelectedBooking(null);
      setAlertMessage({
        type: "success",
        message: t("success.addBookingMessage"),
      });
    },
    onError: (error: any) => {
      console.log(error);
      closeModal();
      setAlertMessage({
        type: "error",
        message: t(`errors.${error?.response?.data?.error}`),
      });
    },
  });

  const submitForm = async (status?: BookingStatusEnum.CANCELED) => {
    const isValid = await form.trigger();
    const values = form.getValues();

    if (isValid) {
      const mutateObject: any = {
        visitorInfos: {
          fullName: `${values.fullName}`,
          email: values.email,
          phoneNumber: values.phone,
        },
      };
      if (isANewBooking) {
        mutateObject["bookings"] = values.prices.map((price) => {
          return {
            numberOfUnit: Number(price.number),
            priceName: price.name,
            from: `${values.date.format("YYYY-MM-DD")} ${values.slots[0].from}`,
            to: `${values.date.format("YYYY-MM-DD")} ${values.slots[0].to}`,
          };
        });
        mutateObject["source"] = PlatformTypeEnum.HOPLEISURE;
        mutatePostBooking.mutate(mutateObject);
      } else {
        mutateObject["visitorInfos"].id = booking.visitorId;
        mutateObject["bookings"] = values.prices.map((price) => {
          const booking: any = {
            id: Number(price.bookingId),
            numberOfUnit: Number(price.number),
            priceName: price.name,
            from: `${values.date.format("YYYY-MM-DD")} ${values.slots[0].from}`,
            to: `${values.date.format("YYYY-MM-DD")} ${values.slots[0].to}`,
          };
          if (status) booking["status"] = status;
          return booking;
        });
        mutatePutBooking.mutate(mutateObject);
      }
    }
  };

  return (
    <Modal
      open={Boolean(selectedBooking)}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalStyle style={{ gap: "30px", width: "75%", maxHeight: "85%" }}>
        <AgendaConfirmationModal
          open={openUpdateConfirmationModal}
          setOpen={setOpenUpdateConfirmationModal}
          momentStartDate={moment(selectedBooking.start)}
          // #TODO: for the moment we don't allow booking updates, we advice to either confirmm and create a new one, either cancel and create a new one
          submitModal={
            isANewBooking ? submitForm : () => console.log("not updatable yet")
          }
          // // submitModal={submitForm}
          // #TODO: for the moment we don't allow booking updates, we advice to either confirmm and create a new one, either cancel and create a new one
          // so title and eplainations explain this situation in case of update
          title={
            isANewBooking
              ? t("confirmationModal.addConfirmationModalTitle")
              : t(
                  "confirmationModal.notPossibleUpdateForTheMomentConfirmationModalTitle",
                )
          }
          explainations={
            isANewBooking
              ? t("confirmationModal.addConfirmationModalExplainations")
              : t(
                  "confirmationModal.notPossibleUpdateForTheMomentModalExplainations",
                )
          }
          // title={isANewBooking ?  t('confirmationModal.addConfirmationModalTitle') : t('confirmationModal.updateConfirmationModalTitle')}
          // explainations={isANewBooking ?  t('confirmationModal.addConfirmationModalExplainations') : t('confirmationModal.updateConfirmationModalExplainations')}
          cancelButton={t("cancel")}
          validateButton={t("confirm")}
          t={t}
        />
        <MainTitleText>
          {isANewBooking
            ? t("bookings.addBookingTitle")
            : t("bookings.updateBookingTitle")}
        </MainTitleText>
        <form
          style={{
            display: "flex",
            gap: "100px",
            flexDirection: "column",
            overflowY: "scroll",
            width: "100%",
          }}
        >
          <div style={{ flex: 1, display: "flex", width: "100%" }}>
            <div style={{ flex: 1 }}>
              <BookingFormGroup1
                form={form}
                formSlots={formSlots}
                activities={activities}
                setAvaibility={setAvaibility}
                t={t}
                monthAvailabilities={monthAvailabilities}
                setSelectedMonth={setSelectedMonth}
                selectedDay={selectedDay}
                setSelectedDay={setSelectedDay}
                setSelectedYear={setSelectedYear}
              />
            </div>
            {activity && availability ? (
              <div style={{ flex: 1 }}>
                <BookingFormGroup2
                  form={form}
                  formPrices={formPrices}
                  activity={activity}
                  availability={availability}
                  numberOfTicketsInTheBooking={numberOfTicketsInTheBooking}
                  t={t}
                />
              </div>
            ) : null}
          </div>
          {activity ? (
            <div style={{ flex: 1, display: "flex", width: "100%" }}>
              <BookingFormGroup3 form={form} activity={activity} t={t} />
            </div>
          ) : null}
        </form>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            gap: "20px",
          }}
        >
          <Button
            color="info"
            onClick={closeModal}
            fullWidth={true}
            variant="contained"
            type="button"
          >
            {t("back")}
          </Button>
          {/* { !isANewBooking && isBookingCanBeUpdated(booking.status) ? (
                        <Button 
                            color="warning" 
                            onClick={async () => {
                                const isValid = await form.trigger()
                                if (isValid) setOpenCancelConfirmationModal(true)} 
                            }
                            fullWidth={true} 
                            variant="contained" 
                            type="button">{t('bookings.cancelBooking')}
                        </Button>
                    ) : null} */}
          <Button
            disabled={!isBookingCanBeUpdated(booking.status)}
            color="primary"
            onClick={async () => {
              const isValid = await form.trigger();
              const values = form.getValues();
              const ticketsNumber = getTicketsNumber(values.prices);
              if (!ticketsNumber)
                return toastEmitter(t("notSelectedTicketsWarning"), "error");
              if (isValid) setOpenUpdateConfirmationModal(true);
            }}
            fullWidth={true}
            variant="contained"
            type="button"
          >
            {!isANewBooking
              ? t("bookings.updateBooking")
              : t("bookings.addBooking")}
          </Button>
        </div>
      </ModalStyle>
    </Modal>
  );
}
