import { arePropsEqual, isArrayEmpty } from "helpers/helpers";
import React, { useEffect, useState } from "react";
import { EventData } from "../types";
import SelectActivities from "components/discounts/SelectActivities";
import "./AgendaFilters.style.css";
import { Select } from "components/formInputs/Inputs";
import { useForm } from "react-hook-form";
import { getFiltersOptions } from "./AgendaFilters.utils";
import { Activity } from "backend/api/activities";
import { isNumber, isString } from "lodash";
import { handleSelectChange } from "components/tabs/widget/WidgetTab.utils";

const AgendaFilters = (props: {
  events: EventData[];
  selectedActivities: string[];
  setSelectedActivities: Function;
  setSelectedFilter: Function;
  t: any;
}) => {
  const {
    events,
    selectedActivities,
    setSelectedActivities,
    t,
    setSelectedFilter,
  } = props;

  const [selectedActivityIds, setSelectedActivityIds] = useState<string[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const filtersOptions = getFiltersOptions(t);

  const { control, watch } = useForm({
    defaultValues: { filters: "" },
  });

  const selectedFilter = watch("filters");

  useEffect(() => {
    setSelectedFilter(selectedFilter);
  }, [selectedFilter]);

  useEffect(() => {
    const uniqueActivities = new Map();
    events.forEach((item) => {
      const { activityId, title } = item.data;

      if (!uniqueActivities.has(title)) {
        uniqueActivities.set(title, {
          id: activityId,
          informations: { name: title },
        });
      }
    });

    setActivities(Array.from(uniqueActivities.values()));
  }, [events]);

  useEffect(() => {
    let updatedSelectedActvitiesIds: string[] = [];
    if (!isArrayEmpty(activities) && !isArrayEmpty(selectedActivities)) {
      updatedSelectedActvitiesIds = selectedActivities?.reduce(
        (acc: string[], selectedActivity: string) => {
          const activityId = activities.find(
            (activity: Activity) =>
              activity.informations.name === selectedActivity,
          )?.id;
          if (isNumber(activityId)) acc.push(activityId.toString());
          return acc;
        },
        [],
      );
    }
    setSelectedActivityIds(updatedSelectedActvitiesIds);
  }, [activities, selectedActivities]);

  useEffect(() => {
    const activityNames = selectedActivityIds.reduce(
      (acc: string[], selectedActivityId) => {
        const activityName = activities?.find(
          (activity: Activity) => activity.id == Number(selectedActivityId),
        )?.informations?.name;
        if (isString(activityName)) acc.push(activityName);
        return acc;
      },
      [],
    );
    setSelectedActivities(activityNames);
  }, [selectedActivityIds, activities]);

  return (
    <div className="agenda-filters-container">
      <SelectActivities
        selectedActivityIds={selectedActivityIds}
        activities={activities}
        handleSelectChange={(event: any) =>
          handleSelectChange(event, setSelectedActivityIds)
        }
        t={t}
        label={t("agendaHeader.activities")}
        resetSelection={true}
      />
      <Select
        id={"filters"}
        name={"filters"}
        label={t("agendaFilters.label")}
        options={filtersOptions}
        control={control}
        error={""}
        t={t}
        resetSelection={true}
      />
    </div>
  );
};

export default React.memo(AgendaFilters, arePropsEqual);
