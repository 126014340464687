import axios from "axios";
import { z } from "zod";
import { getAuthorizedConfig } from "../../helpers/helpers";
import { DiscountTypesEnum } from "helpers/constants";

export const discount = z.object({
  id: z.number(),
  createdAt: z.string(),
  clientId: z.number(),
  activityId: z.number(),
  name: z.string(),
  discountType: z.enum([
    DiscountTypesEnum.PERCENTAGE,
    DiscountTypesEnum.SUBSTRACTION,
  ]),
  discountNumber: z.number(),
  discount_number: z.number(),
  code: z.string(),
  minBasket: z.number().optional(),
  lastDayDiscount: z.number().optional(),
  startDate: z.string().optional(),
  endDate: z.string().optional(),
  maxUseTickets: z.number().optional(),
  maxUseAmount: z.number().optional(),
  status: z.string()
});

export type Discount = z.infer<typeof discount>;

export async function createDiscount(
  promotion: Partial<Discount>,
): Promise<Discount> {
  let config = getAuthorizedConfig("POST");
  config["url"] = `${process.env.REACT_APP_API_URL}/discounts/`;
  config["data"] = promotion;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export async function getAllDiscountsByClient(): Promise<Discount[]> {
  let config = getAuthorizedConfig("GET");
  config["url"] = `${process.env.REACT_APP_API_URL}/discounts`;
  const response = await axios.request(config);
  const result = response.data;
  return result;
}

export async function deleteDiscount(discountId: number): Promise<void> {
  let config = getAuthorizedConfig("PATCH");
  config["url"] = `${process.env.REACT_APP_API_URL}/discounts/${discountId}`;
  await axios.request(config);
}
