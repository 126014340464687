import { ActivityHours } from "backend/api/activities";
import moment from "moment";
import { useTranslation } from "react-i18next";

const daysNameMapping = [
  "sunday",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
];

export const useScheduleValidation = () => {
  const { t } = useTranslation("activities");

  const calculateSlotDuration = (slot: ActivityHours["dates"]): number => {
    if (slot?.from && slot.to) {
      const [fromHours, fromMinutes] = slot.from.split(":").map(Number);
      const [toHours, toMinutes] = slot.to.split(":").map(Number);

      const fromTotalMinutes = fromHours * 60 + fromMinutes;
      const toTotalMinutes =
        toHours === 0 && toMinutes === 0 ? 24 * 60 : toHours * 60 + toMinutes;

      return toTotalMinutes - fromTotalMinutes;
    }
    return 0;
  };

  const validateSchedule = (scheduleData: ActivityHours) => {
    const errorsEmptySlot: string[] = [];
    const errorsNotValidSlot: string[] = [];
    let startDayOfWeek: number | null = null;
    let endDayOfWeek: number | null = null;
    if (scheduleData && scheduleData.dates) {
      const startDate = moment(scheduleData.dates.from);
      const endDate = moment(scheduleData.dates.to);
      const diffDay = endDate.diff(startDate, "days");
      startDayOfWeek = diffDay < 7 ? +startDate.format("d") : 0;
      endDayOfWeek = diffDay < 7 ? startDayOfWeek + diffDay : 6;
    }
    Object.entries(scheduleData.hours).forEach(([key, value]) => {
      if (
        startDayOfWeek !== null &&
        endDayOfWeek !== null &&
        startDayOfWeek <= +key &&
        +key <= endDayOfWeek &&
        value.slots.length === 0
      ) {
        errorsEmptySlot.push(t(`day.${daysNameMapping[+key]}`));
      }
      value.slots.forEach((slot) => {
        const duration = calculateSlotDuration(slot);
        if (duration < scheduleData.slotDuration) {
          errorsNotValidSlot.push(t(`day.${daysNameMapping[+key]}`));
        }
      });
    });
    return { errorsEmptySlot, errorsNotValidSlot };
  };

  return { validateSchedule };
};
