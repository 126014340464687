import Navbar from "components/navigation/Navbar";
import "./WebsitePage.css";
import { useTranslation } from "react-i18next";
import React from "react";
import WidgetTab from "components/tabs/widget/WidgetTab";
import { arePropsEqual } from "helpers/helpers";
import MainTitle from "components/general/MainTitle";

const WebsitePage = () => {
  const { t } = useTranslation("website");

  return (
    <div className="website-page-container">
      <Navbar />
      <div className="website-page-content">
        <MainTitle title={t("website")} />
        <WidgetTab />
      </div>
    </div>
  );
};

export default React.memo(WebsitePage, arePropsEqual);
