import { isArrayEmpty } from "helpers/helpers";
import { PriceForm } from "./bookingFormValidator";

export const getTicketsNumber = (prices: PriceForm[]): number => {
  if (isArrayEmpty(prices)) return 0;
  return prices.reduce((ticketsNumber: number, price: PriceForm) => {
    ticketsNumber += price.number;
    return ticketsNumber;
  }, 0);
};
