import axios from "axios";
import {
  GoogleFontFamilyType,
  SelectOption,
  WidgetType,
} from "./WidgetTab.types";
import { Activity } from "backend/api/activities";
import { SelectChangeEvent } from "@mui/material";

export const getGoogleTopFontFamilies = async () => {
  try {
    const { data } =
      await axios.get(`https://www.googleapis.com/webfonts/v1/webfonts?key=${process.env.REACT_APP_GOOGLE_FONTS_API_KEY}&sort=popularity
  `);
    const topFonts = data.items.slice(0, 50); // Top 50 popular fonts
    return topFonts;
  } catch (error) {}
};

export const formatFontFamiliesOptions = (
  fontFamilies: GoogleFontFamilyType[],
): SelectOption[] => {
  return fontFamilies?.map((fontFamily: GoogleFontFamilyType) => ({
    value: fontFamily.family,
    label: fontFamily.family,
  }));
};

export const formatActivitiesIds = (widget: WidgetType) => {
  if (widget) return widget?.activitiesIds?.map((id) => String(id));
  else return [];
};

export const getIframeCode = (widgetId: number | undefined) => {
  return widgetId
    ? `<iframe
src="https://widget.hopleisure.com?widgetId=${widgetId}"
frameborder="0"
allowfullscreen
width="90%"
height="700px"
></iframe>`
    : "";
};

export const isWidgetNameAlreadyUsed = (
  newWidgetName: string,
  widgets: WidgetType[],
) => {
  return widgets.some((widget) => widget.name === newWidgetName);
};

export const handleSelectChange = (
  event: SelectChangeEvent<string[]>,
  setterFct: Function,
) => {
  const {
    target: { value },
  } = event;
  setterFct(typeof value === "string" ? value.split(",") : value);
};
