import { CalendarViewEnum } from "pages/agenda/Agenda.types";
import { EventData } from "../types";
import { Button } from "@mui/material";
import moment from "moment";
import { DateWithArrows } from "./dateWithArrows/DateWithArrows";
import "./AgendaHeader.style.css";
import { buttonStyle, formatDateToMonthYear } from "./AgendaHeader.utils";

export function AgendaHeader(props: {
  events: EventData[];
  date: moment.Moment;
  view: CalendarViewEnum;
  setDate: any;
  setView: any;
  selectedMoment: any;
  setSelectedMoment: any;
  selectedActivities: any;
  setSelectedActivities: any;
  t: any;
}) {
  const { date, view, setDate, setView, t } = props;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        paddingRight: "30px",
        paddingLeft: "30px",
        justifyContent: "space-between",
        height: "100px",
      }}
    >
      <div>
        <span className="agenda-header-month">
          {formatDateToMonthYear(date)}
        </span>
        <DateWithArrows date={date} setDate={setDate} view={view} t={t} />
      </div>
      <div className="agenda-day-week-container">
        <Button
          variant="contained"
          style={buttonStyle(view === CalendarViewEnum.WEEK)}
          onClick={() => {
            setDate(moment());
            setView(CalendarViewEnum.WEEK);
          }}
        >
          {t("agendaHeader.week")}
        </Button>
        <Button
          variant="contained"
          style={buttonStyle(view === CalendarViewEnum.DAY)}
          onClick={() => {
            setDate(moment());
            setView(CalendarViewEnum.DAY);
          }}
        >
          {t("agendaHeader.day")}
        </Button>
      </div>
    </div>
  );
}
