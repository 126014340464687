import { Box, Button, Modal, Paper } from "@mui/material";
import { getAllClientActivitiesNameAndIds } from "backend/api/activities";
import { createUsers, deleteUsers, getUsers, updateUsers } from "backend/api/profile";
import AlertMessage from "components/general/AlertMessage";
import { MainPageCard } from "helpers/generalStyles";
import { Message } from "helpers/helpers";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import CardNewUser from "./cardsProfile/cardNewUser";
import CardUser from "./cardsProfile/cardUser";
import ResponseError from "hooks/ResponseError";
import { useNavigate } from "react-router-dom";

type Props = {
  t: any;
  id: string;
};

const ProfileTeam = ({ t, id }: Props) => {
  const navigate = useNavigate();

  const { t: tProfile } = useTranslation("profile");
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });

  const [idUser, setIdUser] = useState("");

  const [isEdit, setIsEdit] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState(false);

  const [dataList, setDataList] = useState<any>([]);

  const [dataActivities, setDataActivities] = useState<any>([]);

  useQuery({
    queryKey: ["getAllClientActivitiesNameAndIds"],
    queryFn: () => getAllClientActivitiesNameAndIds(),
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setDataActivities(data);
    },
  });

  const { refetch: refetchUsers } = useQuery("users", getUsers, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const newData = data.filter((item: any) => item.status !== "DELETED");
      const newResult = newData.map((item: any) => {
        return {
          ...item,
          activities: item.activities
            ? item.activities.map(String)
            : [],
          isLockerOpen: false,
        };
      });
      setDataList(newResult);
    },
    onError: (error) => {
      ResponseError(error, navigate);
    },
  });

  const { mutate: mutateCreateUser } = useMutation({
    mutationFn: createUsers,
    onSuccess: (result: any) => {
      refetchUsers();
      setIsEdit(false);

      setAlertMessage({ type: "success", message: tProfile("USER_CREATED") });
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: tProfile(`${error?.response?.data?.error}`),
      });
    },
  });

  const { mutate: mutateUpdateUser } = useMutation({
    mutationFn: updateUsers,
    onSuccess: (result: any) => {
      refetchUsers();
      setIsEdit(false);

      setAlertMessage({ type: "success", message: tProfile("USER_UPDATED") });
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: tProfile(`${error?.response?.data?.error}`),
      });
    },
  });

  const { mutate: mutateDeleteUser } = useMutation({
    mutationFn: deleteUsers,
    onSuccess: (result: any) => {
      refetchUsers();
      handleCloseModalDelete();

      setAlertMessage({ type: "success", message: tProfile("USER_DELETED") });
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: tProfile(`${error?.response?.data?.error}`),
      });
    },
  });

  const handleLockerOpen = (v: any) => {
    setIsEdit(false);

    const newDataList = dataList.map((item: any) => {
      if (item.id === v.id) {
        return {
          ...item,
          isLockerOpen: !item.isLockerOpen,
        };
      } else {
        return {
          ...item,
          isLockerOpen: false,
        };
      }
    });
    setDataList(newDataList);
  };

  const handleOpenModalDelete = (v: any) => {
    handleFillField(v);
    setIsEdit(false);

    setOpenModalDelete(true);

    const newDataList = dataList.map((item: any) => ({
      ...item,
      isLockerOpen: false,
    }));

    setDataList(newDataList);
  };

  const handleCloseModalDelete = () => {
    setOpenModalDelete(false);
    setOpenModalDelete(false);
  };

  const handleFillField = (v: any) => {
    setIdUser(v.id);
  };

  return (
    <MainPageCard id={id}>
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={tProfile}
      />

      <Modal open={openModalDelete} onClose={handleCloseModalDelete}>
        <Box>
          <Box
            sx={{
              color: "#004346",
              fontWeight: "700",
              fontSize: "26px",
              padding: 0,
              textAlign: "center",
            }}
          >
            {t("confirmDeleteUser")}
          </Box>
          <Box
            sx={{
              color: "#004346",
              fontWeight: "700",
              fontSize: "20px",
              padding: 0,
              textAlign: "center",
              mt: "15px",
              mb: "25px",
            }}
          >
            {t("noLongerAccessTheApp")}
          </Box>

          <Box
            sx={{
              padding: 0,
              textAlign: "center",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              margin: "auto",
              gap: "15px",
              width: "70%",
            }}
          >
            <Button
              sx={{
                bgcolor: "#2CA58D",
                borderRadius: "30px",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#248370",
                  cursor: "pointer",
                },
              }}
              variant="contained"
              onClick={() =>
                mutateDeleteUser({
                  id: idUser,
                  status: "DELETED",
                })
              }
            >
              {t("yesDeleteUser")}
            </Button>

            <Button
              sx={{
                bgcolor: "#FFE0764A",
                borderRadius: "30px",
                color: "#7E4501",
                fontWeight: "600",
                "&:hover": {
                  backgroundColor: "#f9ca3048",
                  cursor: "pointer",
                },
              }}
              variant="contained"
              onClick={handleCloseModalDelete}
            >
              {t("noGoBack")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Title>{t("titleTeam").toUpperCase()}</Title>

      <Box
        sx={{
          width: "95%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          p: 2,
        }}
      >
        <Paper
          sx={{
            width: "100%",
            height: "100%",
            overflowX: "auto",
            overflowY: "hidden",
            "&::-webkit-scrollbar": {
              height: "0.4em",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "rgba(0,0,0,.1)",
            },
            boxShadow: "none",
            border: "none",
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            {dataList.map((item: any, index: number) => (
              <CardUser
                key={index}
                t={tProfile}
                item={item}
                handleOpenModalDelete={handleOpenModalDelete}
                handleLockerOpen={handleLockerOpen}
                id={id}
                dataActivities={dataActivities}
                onSave={(item) => {
                  let payload: any = {
                    id: item.id,
                    fullName: item.fullName,
                    email: item.email,
                    phoneNumber: item.phoneNumber,
                    role: item.role,
                    emailAlert: item.emailAlert,
                    smsAlert: item.smsAlert,
                    activities: item.activities.map(Number),
                  };

                  if (item.mimeType !== "" && item.base64 !== "") {
                    payload.profilePicture = {
                      mimeType: item.mimeType || "",
                      base64File: item.base64.split(",")[1] || "",
                    }
                  }  

                  mutateUpdateUser(payload);
                }}
              />
            ))}

            <CardNewUser
              id={id}
              t={tProfile}
              dataActivities={dataActivities}
              onSave={(item) => {
                let payload: any = {
                  fullName: item.fullName,
                  email: item.email,
                  phoneNumber: item.phone,
                  role: item.role,
                  emailAlert: item.emailAlert,
                  smsAlert: item.smsAlert,
                  activities: item.selectedActivityIds.map(Number),
                  profilePicture: {
                    mimeType: item.mimeType || "",
                    base64File: item.base64.split(",")[1] || "",
                  },
                };

                mutateCreateUser(payload);
              }}
              setIsEdit={setIsEdit}
              isEdit={isEdit}
            />
          </Box>
        </Paper>
      </Box>
    </MainPageCard>
  );
};

export default ProfileTeam;

const Title = styled.div`
  padding-top: 30px;
  color: #2ca58d;
  text-align: center;
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 30px;
`;