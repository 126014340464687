import moment from "moment";
import { CalendarViewEnum } from "pages/agenda/Agenda.types";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

export function DateWithArrows(props: {
  date: moment.Moment;
  setDate: any;
  view: CalendarViewEnum;
  t: any;
}) {
  const { date, setDate, view, t } = props;
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        gap: "10px",
        color: "#888888",
        marginTop: "5px",
      }}
    >
      <ArrowBackIosNewIcon
        style={{ color: "#2ca58d", height: "20px" }}
        onClick={() => {
          const previousDate = moment(date);
          previousDate.subtract(1, view === CalendarViewEnum.DAY ? "d" : "w");
          setDate(previousDate);
        }}
      />
      <div>
        {view === CalendarViewEnum.DAY
          ? date.format("ddd DD MMMM")
          : `${date.startOf("week").format("DD MMMM")} - ${date.endOf("week").format("DD MMMM")}, ${t("agendaHeader.week")} ${date.week()}`}
      </div>
      <ArrowForwardIosIcon
        style={{ color: "#2ca58d", height: "20px" }}
        onClick={() => {
          const previousDate = moment(date);
          previousDate.add(1, view === CalendarViewEnum.DAY ? "d" : "w");
          setDate(previousDate);
        }}
      />
    </div>
  );
}
