import { Close, Edit } from "@mui/icons-material";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import {
  Avatar,
  Button,
  Card,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
} from "@mui/material";
import { InputMultiSelect, InputText, IOSSwitchLabel } from "components/formInputs/Inputs";
import { RoleEnum } from "helpers/constants";
import { FormDiv } from "helpers/generalStyles";
import DefaultProfile from "images/default-profile.png";
import { MuiTelInput } from "mui-tel-input";
import React, { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "./cardNewUser.css";

interface CardNewUserProps {
  dataActivities: { id: string; name: string }[];
  onSave: (item: any) => void;
  isEdit?: boolean;
  setIsEdit: (value: boolean) => void;
  id: string;
  t?: (key: string) => string;
}

interface CreateUserData {
  avatar: string;
  fullName: string;
  email: string;
  phone: string;
  role: string;
  mimeType: string;
  base64: string;
  emailAlert: boolean;
  smsAlert: boolean;
  selectedActivityIds: string[];
}

const CardNewUser: React.FC<CardNewUserProps> = ({
  dataActivities,
  onSave,
  isEdit = false,
  setIsEdit,
  id,
  t = (key) => key,
}) => {
  const [selectedActivityIds, setSelectedActivityIds] = useState<
    (string | number)[]
  >([]);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    reset,
    getValues,
  } = useForm<CreateUserData>({
    defaultValues: {
      avatar: "",
      fullName: "",
      email: "",
      phone: "",
      role: "",
      mimeType: "",
      base64: "",
      emailAlert: false,
      smsAlert: false,
      selectedActivityIds: [],
    },
  });

const { fullName, email, phone, avatar, role, emailAlert, smsAlert } = watch();

  const handleSelectChange = (event: SelectChangeEvent<string[]>) => {
    setValue("selectedActivityIds", event.target.value as string[])
    setSelectedActivityIds(event.target.value as string[]);
  };

  const handleSave = () => {
    try {
      const formData = getValues()
      onSave(formData);
      setSelectedActivityIds([])
      reset()
    } catch (error) {
      console.log(error);
    }
  };

  const onChangeRole = (role: string) => {
    setValue("role", role);
    if (role === RoleEnum.Administrateur) {
      setValue("selectedActivityIds", []);
      setSelectedActivityIds([])
    }
  }

  const onFileChange = (e: any) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      setValue("avatar", URL.createObjectURL(file));

      const mimeType = file.type;
      setValue("mimeType", mimeType);

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result as string;
        setValue("base64", base64);
      };
      reader.readAsDataURL(file);
    }
  }

  return isEdit ? (
    <Card
      sx={{
        width: "30.5%",
        minWidth: "30.5%",
        height: "100%",
        padding: 3,
        borderRadius: "8px",
        backgroundColor: "#F9F9F9",
        border: "none",
      }}
    >
      <FormDiv
        onSubmit={handleSubmit(handleSave)}
        style={{ display: "block", width: "100%", minWidth: "auto", padding: 0 }}
      >
        <Stack direction="column" alignItems="center" spacing={2}>
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{ justifyContent: "space-between", width: "100%" }}
          >
            <div style={{ width: "43px" }}></div>

            <Stack
              direction="row"
              alignItems="center"
              position={"relative"}
              spacing={1}
              sx={{ cursor: "pointer" }}
            >
              <Avatar
                src={avatar ?? DefaultProfile}
                sx={{ width: 80, height: 80 }}
              />
              <IconButton
                aria-label="edit"
                color="primary"
                sx={{ position: "absolute", right: -10, bottom: -10 }}
                onClick={() => fileInputRef.current?.click()}
              >
                <Edit />
              </IconButton>
              <input
                type="file"
                accept="image/*"
                ref={fileInputRef}
                onChange={onFileChange}
                style={{ display: "none" }}
              />
            </Stack>

            <IconButton
              aria-label="close"
              color="primary"
              onClick={() => setIsEdit(false)}
            >
              <Close />
            </IconButton>
          </Stack>

          <Grid container sx={{ gap: 2 }}>
            <Grid item xs={12}>
              <InputText
                name="fullName"
                id="fullName"
                label={t("inputCard.fullname")}
                control={control}
                t={t}
              />
            </Grid>

            <Grid item xs={12}>
              <InputText
                name="email"
                id="email"
                label={t("inputCard.email")}
                control={control}
                t={t}
              />
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              gap: 2,
            }}
          >
            <MuiTelInput
              fullWidth
              value={phone}
              onChange={(value) => setValue("phone", value)}
              variant="outlined"
              inputProps={{
                pattern: "^+?[1-9]d{1,3}s?d{4,14}(?:s?(?:x|ext|extension)s?d+)?$"
              }}
              sx={{
                mt: 0,
                width: "100%",
                "& input": {
                  padding: "14px 0",
                  border: "none",
                },
                border: "none",
                outline: "none",
              }}
            />
          </Grid>

          <Grid
            container
            sx={{
              gap: 2,
            }}
            style={{ height: "170px" }}
          >
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>{t("inputCard.role")}</InputLabel>
                <Select
                  value={role}
                  label={t("inputCard.role")}
                  onChange={(e) => onChangeRole(e.target.value)}
                >
                  <MenuItem value={RoleEnum.Administrateur}>{t("administrator")}</MenuItem>
                  <MenuItem value={RoleEnum.Membre}>{t("member")}</MenuItem>
                  <MenuItem value={RoleEnum.Invité}>{t("invite")}</MenuItem>
                </Select>
              </FormControl>

              {role === RoleEnum.Membre && (
                <>
                  <p className="editor-access">
                    {t("inputCard.editor_access")} :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {t("inputCard.editor_member_value")}
                    </span>
                  </p>
                  <p className="no-access">
                    {t("inputCard.activities_no_access")} :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {t("inputCard.activities_member_value")}
                    </span>
                  </p>
                </>
              )}
              {role === RoleEnum.Invité && (
                <>
                  <p className="editor-access">
                    {t("inputCard.editor_access")} :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {t("inputCard.editor_invite_value")}
                    </span>
                  </p>
                  <p className="no-access">
                    {t("inputCard.no_access")} :{" "}
                    <span style={{ fontWeight: "400" }}>
                      {t("inputCard.no_access_invite_value")}
                    </span>
                  </p>
                </>
              )}
              {role === RoleEnum.Administrateur && (
                <p className="text-access">
                  {t("inputCard.editor_access")} :{" "}
                  <span style={{ fontWeight: "300" }}>
                    {t("inputCard.all_modules")}
                  </span>
                </p>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            sx={{
              gap: 2,
              height: 75
            }}
          >
            {role !== "Administrateur" && (
              <Grid item xs={12}>
                <InputMultiSelect
                  value={selectedActivityIds.map((id) => id.toString()) ?? []}
                  onChange={handleSelectChange}
                  label={t("inputCard.activities")}
                  id={id || "activity_id"}
                  placeholder={t("inputCard.activities")}
                  name={"activity_id"}
                  options={dataActivities.map(
                    (activity: { id: { toString: () => any }; name: any }) => ({
                      value: activity.id.toString(),
                      label: activity.name,
                    })
                  )}
                  required
                  error={null}
                  autoComplete="off"
                  t={t}
                />
              </Grid>
            )}
          </Grid>

          <Grid
            container
            sx={{
              gap: 2,
            }}
            paddingLeft={1.5}
          >
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <IOSSwitchLabel
                  checked={emailAlert}
                  label={t("inputCard.toggle_email")}
                  value={emailAlert}
                  setValue={(v) => setValue("emailAlert", v)}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "start",
                }}
              >
                <IOSSwitchLabel
                  checked={smsAlert}
                  label={t("inputCard.toogle_sms")}
                  value={smsAlert}
                  setValue={(v) => setValue("smsAlert", v)}
                />
              </div>
            </Grid>
          </Grid>

          <Button
            variant="contained"
            color="info"
            disabled={
              !fullName ||
              !email ||
              !phone ||
              !role ||
              (role !== "Administrateur" && selectedActivityIds.length === 0)
            }
            type="submit"
          >
            {t("inputCard.create_btn")}
          </Button>
        </Stack>
      </FormDiv>
    </Card>
  ) : (
    <Card
      sx={{
        width: "30.5%",
        minWidth: "30.5%",
        padding: 3,
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => setIsEdit(true)}
    >
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          spacing={1}
          position={"relative"}
          sx={{
            cursor: "pointer",
          }}
        >
          <Avatar src={DefaultProfile} sx={{ width: 80, height: 80 }} />
          <IconButton
            aria-label="edit"
            color="primary"
            sx={{
              position: "absolute",
              right: -10,
              bottom: -10,
            }}
          >
            <AddCircleRoundedIcon />
          </IconButton>
        </Stack>
      </Stack>
    </Card>
  );
};

export default CardNewUser;