import React, { useState } from "react";
import { StripeConnectButton } from "./style";
import {
  stripeConnect,
  stripeConnectVerification,
} from "backend/api/stripeConnect";
import Loading from "components/general/Loading";
import { useUser } from "contexts/UserContext";
import { Text } from "helpers/generalStyles";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Message } from "helpers/helpers";
import AlertMessage from "components/general/AlertMessage";
import styled from "styled-components";

const StripeConnect = () => {
  const { t } = useTranslation("profile");
  const { client } = useUser();
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    data: stripeVerification,
    isLoading: isStripeConnectVerificationLoading,
  } = useQuery({
    queryKey: [`stripeConnectVerification`, client?.id],
    queryFn: () => stripeConnectVerification(client?.id || ""),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleConnectStripe = async () => {
    setIsLoading(true);
    try {
      const connectUrl = await stripeConnect();
      window.location.href = connectUrl;
    } catch {
      setIsLoading(false);
      setAlertMessage({ message: t("errors.stripeConnect"), type: "error" });
    }
  };

  return (
    <>
      <AlertMessage
        alertMessage={alertMessage.message}
        setAlertMessage={setAlertMessage}
        type={alertMessage.type}
        t={t}
      />
      {(isLoading || isStripeConnectVerificationLoading) && <Loading />}
      {stripeVerification?.accountCompleted && (
        <Text size="18px">Vous avez déjà connecté un compte de paiement</Text>
      )}
      {stripeVerification?.accountCompleted && (
        <StripeConnectDashboardButton
          onClick={() =>
            window.open("https://dashboard.stripe.com/login", "_blank")
          }
        >
          Consulter mon tableau de bord Stripe
        </StripeConnectDashboardButton>
      )}
      <StripeConnectButton onClick={handleConnectStripe}>
        {stripeVerification?.accountCompleted
          ? "Consulter mes informations de comptes Stripe"
          : "Connecter un compte de Paiement"}
      </StripeConnectButton>
    </>
  );
};

export default StripeConnect;

const StripeConnectDashboardButton = styled(StripeConnectButton)`
  margin-bottom: 0;
  margin-top: 30px;
  background-color: white;
  color: #6772e5;
  border: 2px solid #6772e5;
`;
