import { DiscountDiv } from "./style";
import { TextField } from "@mui/material";

interface AddDiscountDateProps {
  t: any;
  disabled?: boolean;
  valueStartDate: string;
  valueEndDate: string;
  onChangeStartDate: (value: string) => void;
  onChangeEndDate: (value: string) => void;
}

const AddDiscountDate = ({
  t,
  disabled,
  valueStartDate,
  valueEndDate,
  onChangeStartDate,
  onChangeEndDate,
}: AddDiscountDateProps) => {
  const currentDate = new Date().toISOString().split("T")[0];
  return (
    <DiscountDiv>
      <TextField
        inputProps={{
          type: "date",
          inputMode: "text",
          min: currentDate,
          max: valueEndDate
        }}
        disabled={disabled}
        id="startDate"
        multiline={false}
        required
        variant="outlined"
        fullWidth={true}
        label={t("form.create.discountAcceptedFrom")}
        type={"date"}
        value={String(valueStartDate)}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(event) => {
          const value = event.target.value;
          if (value === "") onChangeStartDate("");
          else {
            onChangeStartDate(value);
          }
        }}
      />
      <TextField
        inputProps={{
          type: "date",
          inputMode: "text",
          min: valueStartDate || currentDate
        }}
        disabled={disabled}
        id="endDate"
        required
        multiline={false}
        variant="outlined"
        fullWidth={true}
        label={t("form.create.discountAcceptedUntil")}
        InputLabelProps={{
          shrink: true,
        }}
        type={"date"}
        value={String(valueEndDate)}
        onChange={(event) => {
          const value = event.target.value;
          if (value === "") onChangeEndDate("");
          else {
            onChangeEndDate(value);
          }
        }}
      />
    </DiscountDiv>
  );
};

export default AddDiscountDate;
