import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { InputContainer, InputNumberContainer, InputSelectContainer } from "./style";

interface AddUsageLimitProps {
  t: any;
  disabled?: boolean;
  valueAmount: number | undefined;
  valueType: string;
  onChangeAmount: (value: number) => void;
  onChangeType: (value: any) => void;
  options: { value: string; label: string; disabled?: boolean }[];
  placeholder?: string;
}

const AddUsageLimit = ({
  t,
  disabled,
  valueAmount,
  valueType,
  onChangeAmount,
  onChangeType,
  options,
  placeholder
}: AddUsageLimitProps) => {
  return (
    <InputContainer>
      <InputNumberContainer>
        <TextField
          inputProps={{
            type: "number",
            inputMode: "decimal",
            min: 1,
            step: 1,
          }}
          disabled={disabled}
          label={t("form.create.setLimit")}
          id="limitAmount"
          multiline={false}
          variant="outlined"
          fullWidth={true}
          required
          type={"decimal"}
          value={String(valueAmount)}
          onChange={(event) => {
            const value = event.target.value;
            if (value === "") onChangeAmount(0);
            else {
              onChangeAmount(Number(value));
            }
          }}
        />
      </InputNumberContainer>
      <InputSelectContainer>
        <FormControl fullWidth={true}>
          <InputLabel>{t("form.create.limitType")}</InputLabel>
          <Select
            label={t("form.create.limitType")}
            displayEmpty
            disabled={disabled}
            id={"limitType"}
            required
            variant="outlined"
            value={valueType}
            renderValue={(selected) => {
              if (selected === "") return <em>{placeholder}</em>;
              else {
                const selectedLabel = options.find(
                  (item) => item.value === String(selected)
                )?.label;
                return selectedLabel;
              }
            }}
            onChange={(event) => {
              onChangeType(event);
            }}
          >
            <MenuItem disabled value="">
              <em>{placeholder}</em>
            </MenuItem>
            {options.map((option, index) => (
              <MenuItem
                key={index}
                disabled={option.disabled}
                value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </InputSelectContainer>
    </InputContainer>
  );
};

export default AddUsageLimit;
