import React from "react";
import { Activity, ActivityInformations } from "backend/api/activities";
import { Discount } from "backend/api/discounts";

import { DiscountListContainer } from "./style";
import DiscountCard from "./DiscountCard";
import { Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isArrayEmpty } from "helpers/helpers";

export interface ActivityWithDiscountId extends Activity {
  discountId: number;
}

export interface DiscountWithActivities extends Discount {
  activities: ActivityWithDiscountId[];
  associatedDiscountIds: number[];
}

interface DiscountListProps {
  discounts: Discount[];
  activities: Activity[];
}

const DiscountList = ({ discounts, activities }: DiscountListProps) => {
  const { t } = useTranslation("discounts");

  const getValidActivities = (activityId: number, discountId: number) => {
    return activities
      .filter(
        (activity) =>
          activity.id === activityId && !activity.informations.isDeleted,
      )
      .map((activity) => ({ ...activity, discountId }));
  };

  // Process discounts with associated activities
  const discountsWithActivities: DiscountWithActivities[] = discounts.reduce(
    (acc: DiscountWithActivities[], discount) => {
      const validActivities = getValidActivities(
        discount.activityId,
        discount.id,
      );

      if (isArrayEmpty(validActivities)) {
        return acc;
      }

      const existingDiscount = acc.find((d) => d.code === discount.code);

      if (existingDiscount) {
        existingDiscount.activities.push(...validActivities);
        existingDiscount.associatedDiscountIds = Array.from(
          new Set([...existingDiscount.associatedDiscountIds, discount.id]),
        );
      } else {
        acc.push({
          ...discount,
          activities: validActivities,
          associatedDiscountIds: [discount.id],
        });
      }

      return acc;
    },
    [],
  );

  return (
    <DiscountListContainer>
      {discountsWithActivities?.length > 0 ? (
        discountsWithActivities.map((discount, index) => (
          <React.Fragment key={index}>
            <DiscountCard discount={discount} />
            {index !== discountsWithActivities.length - 1 && (
              <Divider sx={{ width: "100%", mt: "30px", mb: "30px" }} />
            )}
          </React.Fragment>
        ))
      ) : (
        <p>{t("noDiscounts")}</p>
      )}
    </DiscountListContainer>
  );
};

export default DiscountList;
