import { useEffect, useState } from "react";
import { useBlocker } from "react-router-dom";

export const useNavigationBlocker = () => {
  const [blockCondition, setBlockCondition] = useState(false);

  let blocker = useBlocker(({ currentLocation, nextLocation }): any => {
    const isLocationChanging =
      currentLocation.pathname !== nextLocation.pathname;
    if (isLocationChanging && blockCondition) {
      return true;
    }
  });
  useEffect(() => {
    return () => {
      if (blocker?.state === "proceeding") {
        //@ts-ignore
        blocker.reset();
      }
    };
  }, [blocker]);

  return {
    navigationBlocker: blocker,
    setBlockCondition,
  };
};
