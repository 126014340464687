import { MainPageCard } from "helpers/generalStyles";
import { ActivityForm } from "pages/Activities";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { emptyActivity } from "helpers/constants";
import ActivityType from "../ActivityType";
import { arePropsEqual } from "helpers/helpers";
import { useLocation, useNavigate } from "react-router-dom";
import { Container } from "../style";
import { IconButton } from "@mui/material";

const ActivitiesHeaderCard = (props: {
  activity: ActivityForm | undefined;
  setActivity: Dispatch<SetStateAction<ActivityForm | undefined>>;
  setActivities: Dispatch<SetStateAction<ActivityForm[]>>;
  activities: ActivityForm[];
}) => {
  const { activity, setActivity, setActivities, activities } = props;
  const [selectedActivity, setSelectedActivity] = useState(activity);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === `/activities/id/${selectedActivity?.id}`) {
      setActivity(selectedActivity);
    }
  }, [location, selectedActivity]);

  return (
    <Container style={{ minHeight: "50px", width: "100%" }}>
      {activities.map((activityElem, index) => (
        <React.Fragment key={index}>
          {!activityElem.informations?.isDeleted && (
            <ActivityType
              key={index}
              id={activityElem?.id}
              informations={activityElem.informations}
              selected={
                activity !== undefined && activity?.id === activityElem?.id
              }
              disabled={!activityElem.informations.isEnabled}
              onClick={() => {
                navigate(`/activities/id/${activityElem.id}`);
                setSelectedActivity(activityElem);
              }}
              setActivity={setActivity}
              activities={activities}
              setActivities={setActivities}
            />
          )}
        </React.Fragment>
      ))}
      <IconButton
        onClick={() => {
          const containAlreadyANewActivity = activities.find(
            (item) => !item.id,
          );
          if (!containAlreadyANewActivity) {
            setActivities([...activities, emptyActivity]);
            setActivity(emptyActivity);
          }
        }}
      >
        <AddCircleOutlineIcon
          fontSize="large"
          style={{
            color: "#4FD1C5",
            cursor: "pointer",
          }}
        />
      </IconButton>
    </Container>
  );
};

export default React.memo(ActivitiesHeaderCard, arePropsEqual);
