import { TextField } from "@mui/material";

interface AddLastDayDiscountProps {
  t: any;
  disabled?: boolean;
  value: number;
  onChange: (value: number) => void;
}

const AddLastDayDiscount = ({
  t,
  disabled,
  value,
  onChange
}: AddLastDayDiscountProps) => {
  return (
    <TextField
    inputProps={{
      type: "number",
      inputMode: "decimal",
      min: 1,
      step: 1,
    }}
    disabled={disabled}
    required
    label={t("form.create.discountLastDay")}
    id="lastDayDiscount"
    multiline={false}
    variant="outlined"
    fullWidth={true}
    type={"decimal"}
    value={String(value)}
    onChange={(event) => {
      const value = event.target.value;
      if (value === "") onChange(0);
      else {
        onChange(Number(value));
      }
    }}
  />
  );
};

export default AddLastDayDiscount;
