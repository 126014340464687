export const enum CalendarViewEnum {
  DAY = "day",
  WEEK = "week",
}

export const enum CalendarMomentOfDayEnum {
  MORNING = "MORNING",
  AFTERNOON = "AFTERNOON",
  EVENING = "EVENING",
}
