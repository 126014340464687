import { DATEFORMAT } from "helpers/constants";
import { Moment } from "moment";

export const buttonStyle = (isActive: boolean) => ({
  backgroundColor: isActive ? "white" : "#e0e1e2",
  color: isActive ? "#008670" : "#9A9A9A",
  ...(!isActive && { boxShadow: "none" }),
  fontWeight: "600",
});

export function formatDateToMonthYear(momentDate: Moment) {
  if (!momentDate || !momentDate.isValid()) {
    throw new Error("Invalid moment object");
  }
  return momentDate.format(DATEFORMAT.MONTH_YEAR);
}
