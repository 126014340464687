import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { FormDiv, SecondaryTitleText } from "../../../helpers/generalStyles";
import { UncontrolledSwitch } from "../../formInputs/Inputs";
import Locker from "../Locker";
import {
  ActivityChannels,
  ActivityInformations,
  upsertActivity as apiUpsertActivity,
} from "../../../backend/api/activities";
import { useMutation, useQuery } from "react-query";
import { queryClient } from "../../..";
import {
  Platform,
  getAllPlatforms as apiGetAllPlatforms,
} from "../../../backend/api/platforms";
import { Message, pick, toastEmitter } from "../../../helpers/helpers";
import AlertMessage from "../../general/AlertMessage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ACTIVITY_SAVE_STATE_UPDATE } from "constants/activityConstants";
import { Container } from "../style";

export function ChannelsForm(props: {
  inputData: ActivityChannels;
  activityId: number | null;
  isLockerOpen: boolean;
  setIsLockerOpen: any;
  navigate: any;
  t: any;
  handleActivateActivitySwitchChange: Function;
  activityInformations: ActivityInformations;
}) {
  const {
    inputData,
    activityId,
    isLockerOpen,
    setIsLockerOpen,
    navigate,
    t,
    handleActivateActivitySwitchChange,
    activityInformations,
  } = { ...props };
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });
  const dispatch = useDispatch();
  const { saveActivityInProgress } = useSelector(
    (state: RootState) => state.activity,
  );
  const [platforms, setPlatforms] = useState<Platform[]>([]);
  const [activityPlatforms, setActivityPlatforms] = useState<Channel[]>([]);
  interface Channel extends Platform {
    status: string;
  }
  const [areChannelsChanged, setAreChannelsChanged] = useState(false);

  useEffect(() => {
    setAlertMessage({ message: "", type: "success" });
  }, [inputData]);

  useEffect(() => {
    const activityPlatforms = platforms.map((item) => {
      return {
        ...item,
        status: inputData.find(
          (inputDataItem) =>
            inputDataItem.platformId === item.id &&
            inputDataItem.status === "OPENED",
        )
          ? "OPENED"
          : "CLOSED",
      };
    });
    setActivityPlatforms(activityPlatforms);
  }, [platforms, inputData]);

  useQuery({
    queryKey: ["getAllPlatforms"],
    queryFn: apiGetAllPlatforms,
    // staleTime: 60000,
    refetchOnWindowFocus: false,
    onSuccess(data) {
      setPlatforms(data);
    },
  });

  const mutateActivity = useMutation({
    mutationFn: (data: ActivityChannels) =>
      apiUpsertActivity({
        channels: [...data],
        informations: activityInformations,
        activityId: activityId ? String(activityId) : null,
      }),
    onSuccess: (result: any) => {
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesWithDisabled"],
      });
      toastEmitter(t("saveActivityChannelsSuccessfully"), "success");
      setAreChannelsChanged(false);
      setIsLockerOpen(false);
      dispatch({ type: ACTIVITY_SAVE_STATE_UPDATE, channels: true });
    },
    onError: (error: any) => {
      console.log(error);
      setAreChannelsChanged(false);
      setAlertMessage({
        type: "error",
        message: t(error?.response?.data?.error),
      });
    },
  });

  const onSubmit = () => {
    const formatData = activityPlatforms.map((item) => {
      return { ...pick(item, ["name", "status"]), platformId: item.id };
    });
    mutateActivity.mutate(formatData);
  };

  useEffect(() => {
    dispatch({
      type: ACTIVITY_SAVE_STATE_UPDATE,
      payload: { channels: !areChannelsChanged },
    });
    if (saveActivityInProgress && areChannelsChanged) {
      onSubmit();
    }
  }, [saveActivityInProgress, areChannelsChanged]);

  const handleSwitchChange = (platformId: number, status: string) => {
    const newActivityPlatforms = [...activityPlatforms];
    let activityPlatformIndex = newActivityPlatforms.findIndex(
      (item) => item.id === platformId,
    );
    newActivityPlatforms[activityPlatformIndex] = {
      ...newActivityPlatforms[activityPlatformIndex],
      status: status === "OPENED" ? "CLOSED" : "OPENED",
    };
    setActivityPlatforms(newActivityPlatforms);
    setAreChannelsChanged(true);
  };
  return (
    <Container>
      <Locker
        isOpen={isLockerOpen}
        onClick={() => setIsLockerOpen(!isLockerOpen)}
      />
      <SecondaryTitleText>
        {t("sellChannelsSubTitle").toUpperCase()}
      </SecondaryTitleText>
      <FormDiv style={{ minWidth: "200px" }}>
        <AlertMessage
          alertMessage={alertMessage.message}
          type={alertMessage.type}
          t={t}
        />
        <div>
          <UncontrolledSwitch
            label={t("isEnabled")}
            isActive={activityInformations.isEnabled}
            onChange={() => {
              handleActivateActivitySwitchChange();
              setAreChannelsChanged(true);
            }}
            disabled={!isLockerOpen}
            t={t}
          />

          <div>
            <ChannelsSubTitle>{t("sellChannelsSectionTitle")}</ChannelsSubTitle>
            <ChannelsSwitchDiv>
              {activityPlatforms.map((channel, index) => (
                <UncontrolledSwitch
                  key={index}
                  label={channel.name}
                  isActive={channel.status === "OPENED"}
                  onChange={() =>
                    handleSwitchChange(channel.id, channel.status)
                  }
                  disabled={!(isLockerOpen && activityInformations.isEnabled)}
                  t={t}
                />
              ))}
            </ChannelsSwitchDiv>
          </div>
        </div>
        <Button
          disabled={!isLockerOpen || mutateActivity.isLoading}
          color="info"
          variant="contained"
          type="button"
          onClick={onSubmit}
        >
          {t("save")}
        </Button>
      </FormDiv>
    </Container>
  );
}

const ChannelsSwitchDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
`;
const ChannelsSubTitle = styled.h3`
  color: #2ca58d;
  text-transform: Uppercase;
  margin: 20px 0 10px;
`;
