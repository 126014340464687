import { ActivityHoursType, Slot } from "backend/api/activities";
import moment from "moment";
import { DayListType, DayType } from "./HoursForm.types";
import { DATEFORMAT } from "helpers/constants";

export const checkDayDisabled = (
  selectedDaysForDuplication: string[],
  dayToCheck: string,
) => {
  return selectedDaysForDuplication.every((day) => day !== dayToCheck);
};

export const getSelectorDaysOptions = (
  mapping: Record<string, string>,
  selectedDaysForDuplication: string[],
): { value: string; label: string; disabled: boolean }[] => {
  return Object.entries(mapping).map(([day, label]) => ({
    value: day,
    label: label,
    disabled: checkDayDisabled(selectedDaysForDuplication, day),
  }));
};

export const getHoursSingleTimeslot = (
  hours: ActivityHoursType,
): Slot | undefined => {
  let openingHours = 0;
  let validOpeningHours = 0;
  let singleOpeningHours;
  const numberOfHoursIntervalAllowed = 1;

  for (const day in hours) {
    const { slots } = hours[day as keyof ActivityHoursType];
    openingHours += slots.length;
    if (slots.length === 1) {
      const [{ from, to }] = slots;
      const isFromDateBeforeToDate = moment(from, DATEFORMAT.TIME).isBefore(
        moment(to, DATEFORMAT.TIME),
      );
      if (isFromDateBeforeToDate) {
        validOpeningHours++;
        singleOpeningHours = { from, to };
      }
    }
    if (
      validOpeningHours > numberOfHoursIntervalAllowed ||
      openingHours > numberOfHoursIntervalAllowed
    ) {
      return undefined;
    }
  }

  return singleOpeningHours;
};

export const duplicateHours = (
  hours: ActivityHoursType,
  selectedDaysForDuplication: string[],
  validOpeningHours: Slot,
) => {
  const duplicatedHours: ActivityHoursType = Object.keys(hours).reduce(
    (acc: any, key: string) => {
      if (
        selectedDaysForDuplication.some(
          (selectedDayForDuplication) => selectedDayForDuplication === key,
        )
      ) {
        acc[key] = { slots: [validOpeningHours] };
      } else acc[key] = { slots: [] };
      return acc;
    },
    {},
  );
  return duplicatedHours;
};

export const getDaysInRange = (
  startDate: string,
  endDate: string,
): DayListType => {
  const days: DayListType = [];
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate, "day")) {
    const dayOfWeek = currentDate.format("d") as DayType;
    days.push(dayOfWeek);
    currentDate.add(1, "day");
  }

  return days;
};
