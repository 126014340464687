import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function TooltipHour() {
  const { t } = useTranslation("activities");
  return (
    <div>
      <Typography fontWeight={700} fontSize={14}>
        {t("scheduleTypes")}:
      </Typography>
      <ul style={{ paddingLeft: "1rem" }}>
        <li>
          "{t("openingHours")}" {t("openingHoursDesc")}
        </li>
      </ul>
      <Typography color="#054643" fontSize={12} fontWeight={700}>
        {t("openingHoursExample")}:
      </Typography>
      <ul style={{ paddingLeft: "1rem" }}>
        <li>
          "{t("timeSlots")}" {t("timeSlotsDesc")}
        </li>
      </ul>
      <Typography color="#054643" fontSize={12} fontWeight={700}>
        {t("timeSlotsExample")}:
      </Typography>
    </div>
  );
}
