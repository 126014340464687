import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Navbar from "../components/navigation/Navbar";
import MainTitle from "../components/general/MainTitle";
import { useQuery } from "react-query";
import { getActivities } from "../backend/api/activities";
import CreateDiscount from "components/discounts/CreateDiscount";
import { Row, Text } from "helpers/generalStyles";
import { getAllDiscountsByClient } from "backend/api/discounts";
import DiscountList from "components/discounts/DiscountList";

export default function Discounts() {
  const { t } = useTranslation("discounts");

  const { data: activities, isLoading: isActivitiesLoading } = useQuery({
    queryKey: ["getActivitiesWithDisabled"],
    queryFn: () => getActivities(true),
    refetchOnWindowFocus: false,
  });

  const { data: discounts, isLoading: isDiscountLoading } = useQuery({
    queryKey: ["getDiscounts"],
    queryFn: getAllDiscountsByClient,
    refetchOnWindowFocus: false,
  });

  return (
    <Page>
      <Navbar />
      <Container>
        <MainTitle title={t("title")} />
        {!isActivitiesLoading && (!activities || activities.length < 1) && (
          <Text size="28px" margin="50px">
            {t("noActivities")}
          </Text>
        )}
        {activities && activities.length > 0 && discounts && (
          <Row width="100%" center>
            <CreateDiscount
              t={t}
              activities={activities}
              discounts={discounts}
            />
            <DiscountList discounts={discounts} activities={activities} />
          </Row>
        )}
      </Container>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`;

const Container = styled.div`
  position: relative;
  margin: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
`;
