import {
  ACTIVITY_SAVE_ORDER,
  ACTIVITY_SAVE_STATE_UPDATE,
} from "constants/activityConstants";

const initialActivityFromsSavedState = {
  informations: true,
  hours: true,
  prices: true,
  channels: true,
};

const checkActivityState = (activity: any) => {
  return Object.values(activity).every((value) => value);
};

export function activityReducer(
  state = {
    activityFromsSavedState: initialActivityFromsSavedState,
    isActivitySaved: true,
    saveActivityInProgress: false,
  },
  action: any,
) {
  const { type, payload } = action;
  switch (type) {
    case ACTIVITY_SAVE_STATE_UPDATE:
      const activityFromsSavedState = {
        ...state.activityFromsSavedState,
        ...payload,
      };
      return {
        ...state,
        isActivitySaved: checkActivityState(activityFromsSavedState),
        activityFromsSavedState,
      };
    case ACTIVITY_SAVE_ORDER:
      return { ...state, saveActivityInProgress: payload };

    default:
      return state;
  }
}
