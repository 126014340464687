import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const SaveFormModal = (props: {
  isSaveFormModalOpen: boolean;
  setIsSaveFormModalOpen: Function;
  setSaveDataChoice: Function;
}) => {
  const { isSaveFormModalOpen, setIsSaveFormModalOpen, setSaveDataChoice } =
    props;
  const { t } = useTranslation("general");

  const closeSaveFormModal = () => {
    setIsSaveFormModalOpen(false);
  };
  const handleButtonClick = (e: any) => {
    const { id } = e.target;
    setSaveDataChoice((prevState: any) => ({
      ...prevState,
      [id]: true,
    }));
    closeSaveFormModal();
  };

  return (
    <Dialog
      open={isSaveFormModalOpen}
      onClose={closeSaveFormModal}
      sx={{
        "& .MuiDialog-paper": {
          width: "max-content",
          maxWidth: "90%",
        },
      }}
    >
      <DialogTitle
        style={{
          fontWeight: "600",
          letterSpacing: "1px",
          color: "#2CA58D",
        }}
      >
        {t("saveDataWarningTitle")}
      </DialogTitle>
      <DialogContent>{t("saveDataWarningContent")}</DialogContent>
      <DialogActions style={{ padding: "1rem" }}>
        <Button
          id="quit"
          onClick={handleButtonClick}
          variant="contained"
          color="error"
        >
          {t("LeaveWithoutSaving")}
        </Button>
        <Button
          onClick={handleButtonClick}
          variant="contained"
          color="secondary"
        >
          {t("continueEditing")}
        </Button>
        <Button
          id="save"
          onClick={handleButtonClick}
          variant="contained"
          color="success"
        >
          {t("save")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SaveFormModal;
