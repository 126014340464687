import { REMOVE_CLIENT, REMOVE_USER, UPDATE_CLIENT, UPDATE_CLIENT_VALUE, UPDATE_USER, UPDATE_USER_VALUE } from "constants/userConstants";

interface UserState {
  user: any;
  client: any;
}

const initialState: UserState = {
  user: {},
  client: {},
};

export const userReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case UPDATE_USER:
      return { ...state, user: action.payload };
    case REMOVE_USER:
      return { ...state, user: {} };
    case UPDATE_USER_VALUE:
      return { ...state, user: { ...state.user, ...action.payload } };
    case UPDATE_CLIENT:
      return { ...state, client: action.payload };
    case REMOVE_CLIENT:
      return { ...state, client: {} };
    case UPDATE_CLIENT_VALUE:
      return { ...state, client: { ...state.client, ...action.payload } };
    default:
      return state;
  }
};

export const updateUser = (payload: any) => ({
  type: UPDATE_USER,
  payload,
});

export const removeUser = () => ({
  type: REMOVE_USER,
});

export const updateUserValue = (payload: any) => ({
  type: UPDATE_USER_VALUE,
  payload,
});
export const updateClient = (payload: any) => ({
  type: UPDATE_CLIENT,
  payload,
});

export const removeClient = () => ({
  type: REMOVE_CLIENT,
});

export const updateClientValue = (payload: any) => ({
  type: UPDATE_CLIENT_VALUE,
  payload,
});
