import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { SelectOption } from "components/tabs/widget/WidgetTab.types";
import { InputContainer, InputNumberContainer, InputSelectContainer } from "./style";

interface SelectDiscountProps {
  t: any;
  disabled?: boolean;
  value: string | undefined;
  onChange: (value: any) => void;
  options: SelectOption[];
  placeholder?: string;
}

const SelectDiscount = ({
  t,
  disabled,
  value,
  onChange,
  options,
  placeholder
}: SelectDiscountProps) => {
  return (
    <InputContainer>
      <FormControl fullWidth={true}>
        <InputLabel>{t("isPinnedDiscount")}</InputLabel>
        <Select
          label={t("isPinnedDiscount")}
          displayEmpty
          disabled={disabled}
          id={"ActivityDiscountCode"}
          variant="outlined"
          required
          value={value}
          renderValue={(selected) => {
            if (selected === "") return <em>{placeholder}</em>;
            else {
              const selectedLabel = options.find(
                (item) => item.value === String(selected)
              )?.label;
              return selectedLabel;
            }
          }}
          onChange={(event) => {
            onChange(event);
          }}
        >
          <MenuItem disabled value="">
            <em>{placeholder}</em>
          </MenuItem>
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={option.value}
            >
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </InputContainer>
  );
};

export default SelectDiscount;
