import styled from "styled-components";
import { useTranslation } from "react-i18next";
import WidgetsIcon from "@mui/icons-material/Widgets";
import FileCopyIcon from "@mui/icons-material/FileCopy";

import Navbar from "components/navigation/Navbar";
import MainTitle from "components/general/MainTitle";
import ProfileInformations from "components/profile/profileInformations";
import { SectionProfileEnum, theme } from "helpers/constants";
import ProfileSettings from "components/profile/profileSettings";
import StripeConnect from "components/profile/StripeConnect";
import ProfileTeam from "components/profile/profileTeam";

export default function Profile() {
  const { t } = useTranslation("profile");
  const { t: generalT } = useTranslation("general");

  const currentSection: SectionProfileEnum =
    window.location.pathname === "/profile"
      ? SectionProfileEnum.General
      : SectionProfileEnum.Invoicing;

  const NAVIGATIONS = [
    {
      title: "GENERAL",
      path: `/profile`,
      icon: (
        <WidgetsIcon
          sx={{
            color:
              window.location.pathname === "/profile"
                ? "white"
                : theme.color.primary,
            mr: 1,
          }}
        />
      ),
    },
    {
      title: generalT("invoicing"),
      path: `/facturation`,
      icon: (
        <FileCopyIcon
          sx={{
            color:
              window.location.pathname === "/facturation"
                ? "white"
                : theme.color.primary,
            mr: 1,
          }}
        />
      ),
    },
  ];

  return (
    <Page>
      <Navbar />
      <Container>
        <MainTitle navigations={NAVIGATIONS} title={t("title")} />

        {currentSection === SectionProfileEnum.General && (
          <>
            <ProfileSettings t={t} id="tour-profile-contact" />
            <ProfileTeam t={t} id="tour-profile-card" />
          </>
        )}
        {currentSection === SectionProfileEnum.Invoicing && (
          <>
            <ProfileInformations t={t} />
            <StripeConnect t={t} />
          </>
        )}
      </Container>
    </Page>
  );
}

const Page = styled.div`
  display: flex;
  height: 100vh;
  position: relative;
  background-color: #fff;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  flex: 1;
  gap: 20px;
  max-height: 100vh;
  overflow-y: auto;
  padding: 20px;
`;
